import Owner from "../pages/Owner";
import { PetEdit, PetInfo, PetList, PetPhoto } from "../pages/Pet";
import { ChangeEmail, ChangePassword, VerifyChangeEmail } from "../pages/Profile";
import News from "../pages/News";
import { Login, RegisterComplete, RegisterStep1, RegisterStep2, RegisterStep3, RegisterStep4, RegisterVerify, ResetPassword } from "../pages/Auth";
import { Question1, Question10, Question11, Question2, Question3, Question4, Question5, Question6, Question7, Question8, Question9 } from "../pages/Questions";
import { Sinsei, SinseiComplete, SinseiMailing } from "../pages/Sinsei";
import { FoodSearch, FoodSupport1, FoodSupport2, FoodSupport3, FoodSupport4, FoodSupport5 } from "../pages/FoodSupport";
import OnepointAdvice from "../pages/OnepointAdvice";
import { FoodAdd } from "../pages/Food";
import { ResultDetail, ResultList } from "../pages/Result";
import FoodDetail from "../pages/FoodSupport/FoodDetail";
import { Privacy, Terms } from "../pages/Pages";

export const routes: Array<any> = [
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/register/step1",
    element: <RegisterStep1 />
  },
  {
    path: "/register/step2",
    element: <RegisterStep2 />
  },
  {
    path: "/register/step3",
    element: <RegisterStep3 />
  },
  {
    path: "/register/step4",
    element: <RegisterStep4 />
  },
  {
    path: "/register/complete",
    element: <RegisterComplete />
  },
  {
    path: "/register/verify",
    element: <RegisterVerify />
  },
  {
    path: "/reset-password",
    element: <ResetPassword />
  },
  {
    path: "/terms",
    element: <Terms />
  },
  {
    path: "/privacy",
    element: <Privacy />
  },
  {
    path: "/mailaddress_register/verify",
    element: <VerifyChangeEmail />
  },
];
  
export const privateRoutes: Array<any> = [
  {
    path: "/pets/new/ques1",
    element: <Question1 />
  },
  {
    path: "/pets/new/ques2",
    element: <Question2 />
  },
  {
    path: "/pets/new/ques3",
    element: <Question3 />
  },
  {
    path: "/pets/new/ques4",
    element: <Question4 />
  },
  {
    path: "/pets/new/ques5",
    element: <Question5 />
  },
  {
    path: "/pets/new/ques6",
    element: <Question6 />
  },
  {
    path: "/pets/new/ques7",
    element: <Question7 />
  },
  {
    path: "/pets/new/ques8",
    element: <Question8 />
  },
  {
    path: "/pets/new/ques9",
    element: <Question9 />
  },
  {
    path: "/pets/new/ques10",
    element: <Question10 />
  },
  {
    path: "/pets/new/ques11",
    element: <Question11 />
  },
  {
    path: "/pets/:id/info",
    element: <PetInfo />
  },
  {
    path: "/pets/list",
    element: <PetList />
  },
  {
    path: "/pets/:id/edit",
    element: <PetEdit />,
    isSelectedPet: true,
  },
  {
    path: "/pet/photo",
    element: <PetPhoto />,
    isSelectedPet: true,
  },
  {
    path: "/sinsei",
    element: <Sinsei />,
    isSelectedPet: true,
  },
  {
    path: "/test/:id/send",
    element: <SinseiMailing />,
    isSelectedPet: true,
  },
  {
    path: "/test/:id/send/complete",
    element: <SinseiComplete />,
    isSelectedPet: true,
  },
  {
    path: "/pets/:id/food-support",
    element: <FoodSupport1 />,
    isSelectedPet: true,
  },
  {
    path: "/pets/:id/food-support1",
    element: <FoodSupport2 />,
    isSelectedPet: true,
  },
  {
    path: "/pets/:id/food-support2",
    element: <FoodSupport3 />,
    isSelectedPet: true,
  },
  {
    path: "/pets/:id/foods",
    element: <FoodSupport5 />,
    isSelectedPet: true,
  },
  {
    path: "/pets/:id/foods/search",
    element: <FoodSearch />,
    isSelectedPet: true,
  },
  {
    path: "/pets/:id/foods-item",
    element: <FoodSupport4 />,
    isSelectedPet: true,
  },
  {
    path: "/foods/:id",
    element: <FoodDetail />,
    isSelectedPet: true,
  },
  {
    path: "/profile",
    element: <Owner />,
  },
  {
    path: "/change-email",
    element: <ChangeEmail />
  },
  {
    path: "/change-password",
    element: <ChangePassword />
  },
  {
    path: "/news",
    element: <News />
  },
  {
    path: "/onepoint-advice",
    element: <OnepointAdvice />
  },
  {
    path: "/foods/add",
    element: <FoodAdd />
  },
  {
    path: "/results",
    element: <ResultList />,
    isSelectedPet: true,
  },
  {
    path: "/results/:id",
    element: <ResultDetail />,
    isSelectedPet: true,
  }
];