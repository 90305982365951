import styled from "styled-components"

const PrivacyPolicyText =
  "個人情報保護方針\n\
  1. 個人情報保護方針 (プライバシーポリシー)\n\
  株式会社ファイブストランドジャパン（以下「当社」と呼びます）は、個人情報の重要性を認識し、その保護を徹底し、法例を遵守すると共に、個人情報の取扱いに際して以下の方針を制定いたします。\n\
  \n\
  2. 個人情報の定義\n\
  本プライバシーポリシーにおいて、個人情報とは個人情報保護法第2条第1項により定義される個人情報を意味するものとします。\n\
  \n\
  3. 個人情報の利用目的\n\
  当社では、ご提供頂いたお客様からの個人情報（以下、「お客様情報」と言う。）、取引先の個人情報（以下、「取引先情報」と言う。）、当社の従業員等の在職者の個人情報（以下、「在職者情報」と言う。）、WEBサイトへのアクセスの履歴情報（以下、「アクセス履歴情報」と言う。）及び通話の情報（以下、「通話情報」と言い、「お客様情報」、「取引先情報」、「在職者情報」、「アクセス履歴情報」及び「通話情報」を総称して以下、「提供個人情報」と言う。）はプライバシーポリシーに沿って慎重に取り扱うと共に、以下の目的で利用させて頂きます。\n\
  尚、提供個人情報はご本人の同意を得られない限り、これ以外の目的で利用されることはありません。但し、当社は業務を円滑に進めるため、業務の一部を委託し、業務委託先に対して必要な範囲内で提供個人情報を提供することがあります。この場合、当社は当該委託先に対して適切な監督を実施します。\n\
  \n\
  (1) お客様情報\n\
  ①当社の商品及びサービスの提供のため\n\
  ②有料サービスを利用しているお客様に利用料金を請求するため\n\
  ③商品・サービスの案内、アンケート、キャンペーン、プレゼントの発送等のため\n\
  ④アフターサービス、問い合わせ、苦情対応等の連絡のため\n\
  ⑤お客様の情報に合わせて当社サービスのページ上における情報、サービス、広告配信をカスタマイズするため\n\
  ⑥当社サービスの改善や新サービス、新機能の開発のため\n\
  ⑦マーケティングデータの調査、統計、分析のため\n\
  ⑧サービス、キャンペーンのレコメンドのため\n\
  ⑨決済サービス、物流サービス提供のため\n\
  ⑩アフィリエイト、ポイントサービスの提供のため\n\
  ⑪履歴分析等によるサービス改善のため\n\
  ⑫新サービス、新機能の開発のため\n\
  ⑬システムの維持、不具合対応のため\n\
  ⑭当社サービスの利用規約に違反する形でのご利用を防止するため\n\
  ⑮当社サービスに関して、個人を識別できない形式に加工した統計データを作成するため\n\
  ⑯本人確認、認証サービスのため\n\
  ※当社サイト内で個人情報の利用目的について別途定めている場合は当該利用目的の記述を優先します。\n\
  (2) 取引先情報\n\
  ①業務上の諸連絡・確認・調査、受発注業務、請求支払業務のため\n\
  ②契約書、御申込書及び利用規約に基づく各種契約事項の履行のため\n\
  (3) 在職者情報\n\
  ①人事管理業務および連絡のため\n\
  (4) アクセス履歴情報\n\
  ①本サービスの保守管理のため\n\
  ②利用状況に関する統計分析のため\n\
  (5) 通話情報\n\
  ①当社における業務品質の向上及び通話内容確認のため\n\
  \n\
  4. 個人情報の取得\n\
  当社は、必要な範囲で個人情報を取得することがあります。また、お客様のIPアドレスやCookie、お客様の閲覧情報をブラウザから受け取りサーバーに記録します。その場合、取得する個人情報の範囲は取得の目的を達成するために必要な限度を超えないものとし、適法かつ公正な手段により行います。\n\
  \n\
  5. 個人情報の管理について\n\
  当社は、個人情報等を各利用目的に応じて適切に管理します。このため、個人情報等の管理責任者の設置、個人情報等への不当なアクセス・紛失・改ざん・漏洩等に対するセキュリティ対策などを実施します。 並びに不適合が発生した場合、その原因を除去し再発しないように是正処置を行います。\n\
  また、当社は個人情報の取り扱いを外部に委託することがあります。この場合、個人情報を適正に取り扱っていると認められる委託先を選定し、委託契約等において個人情報の管理・秘密保持・再提供の禁止等、本人の個人情報の漏洩等無きよう必要な事項を取り決めると共に、適正な管理を実施します。\n\
  \n\
  6. 要配慮個人情報の取得\n\
  当社は、本人から予め同意を得た場合または法令で認められる場合を除き、要配慮個人情報（個人情報保護法第2条第3項に定義されるものを意味します）を取得しません。また、取得した要配慮個人情報については、第三者提供や共同利用は行いません。\n\
  \n\
  7. サービスご利用上の注意点\n\
  当社がご提供する一部のサービス（当社が運営するウェブサイトのサービスを含む）では、お客様から個人情報をご提供いただけない場合はご利用できないものがありますので、予めご了承ください。\n\
  \n\
  8. Cookie(クッキー)とウェブビーコンの使用について\n\
  当社は、本ウェブサイトをより便利にご利用いただくため、本ウェブサイトおよびHTML形式の電子メールの一部において、Cookieとウェブビーコンを単独または併用して使用しています。\n\
  Cookieやウェブビーコンの利用で入手した情報は次の目的で利用いたします。 尚、お客様等のコンピュータを識別することはできますが、お客様等の個人情報を取得することはありません。\n\
  \n\
  (1) 当社のウェブサイトの利便性向上のため\n\
  (2) 広告の適切な配信のため\n\
  (3) 利用状況の把握や統計分析のため\n\
  また、ブラウザの設定変更によりCookieを拒否することができます。但し、Cookieを拒否しますと、本ウェブサイトのサービス内容に制限が発生する、または一部サービスが利用できなくなるなど不具合が生じる場合がありますので予めご了承ください。\n\
  \n\
  9. 第三者への開示・提供\n\
  当社は、ご本人の同意を得ずに、業務委託先を除く第三者に個人情報を開示・提供することはありません。但し、以下の何れかに該当する場合については、ご本人の同意なく個人情報を提供する場合があります。\n\
  \n\
  (1) 法令に基づき開示・提供を求められた場合\n\
  (2) 公衆衛生の向上または児童の健全な育成の推進のために必要な場合でご本人様の同意を得る事が困難である場合\n\
  (3) 人の生命、身体または財産の保護のために必要な場合でご本人様の同意を得ることが困難である場合\n\
  (4) 国または地方公共団体等が公的な事務を実施するうえで、協力する必要がある場合であって、ご本人様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合\n\
  \n\
  10. 個人情報の開示\n\
  当社は、ご本人から個人情報保護法の定めに基づき個人情報の開示を求められた時は、お申し出いただいた方がご本人であることを確認の上で、本人に対し遅滞なく開示を行います(当該個人情報が存在しない時にはその旨を通知いたします)。但し、法令により当社が開示の義務を負わない場合は、この限りではありません。\n\
  \n\
  11. 個人情報の訂正・削除等\n\
  当社は、ご本人から個人情報が真実でないという理由によって、その内容の訂正、追加または削除を求められた場合、お申し出いただいた方がご本人であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき個人情報の内容の訂正、追加または削除を行い、その旨を本人に通知します。但し、法令により当社が訂正等の義務を負わない場合は、この限りではありません。\n\
  \n\
  12. 個人情報の利用停止・消去\n\
  当社は、ご本人から個人情報の利用停止または消去を求められた場合、そのご請求に理由があることが判明した場合には、お申し出いただいた方がご本人であることを確認の上で、遅滞なく個人情報の利用停止または消去を行い、その旨を本人に通知します。但し、法令により当社が利用停止等の義務を負わない場合は、この限りではありません。\n\
  尚、これらの情報の一部もしくは全部を利用停止または消去した場合、不本意ながらご要望に添ったサービスの提供ができなくなることがありますので、ご理解とご協力を賜りますようお願い申し上げます。\n\
  \n\
  13. プライバシーポリシーの改定について\n\
  当社は、法令・各種ガイドライン等の制定や変更等に伴い、プライバシーポリシーの全部または一部を改定することがあります。重要な変更がある場合には、サイト上でお知らせいたします。\n\
  \n\
  \n\
  2022年12月制定\n\
  株式会社ファイブストランドジャパン\n\
  代表取締役　渡辺和貴\n\
";

const Privacy = () => {
  return (<>
    <Wrapper className="pt-12">
      <h1 className="flex justify-center align-center font-bold mb-8">個人情報保護方針</h1>
      <LongText>{PrivacyPolicyText}</LongText>
      <div className="text-center mt-6">
        <button
          onClick={() => {
            window.history.back();
          }}
        >戻る</button>
      </div>
    </Wrapper>
  </>)
}

export default Privacy

const Wrapper = styled.div`
`

const LongText = styled.div`
  font-size: 1rem;
  white-space: pre-wrap;
`;
