import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { useApp } from "../../hooks"
import { PetfoodService } from "../../api"
import { LinkGroup } from "../../components/common"

const FoodSupport5 = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const {setIsLoading} = useApp()
  const [foods, setFoods] = useState([])

  useEffect(() => {
    const getList = async (id: string) => {
      try {
        setIsLoading(true)
        const {data} = await PetfoodService.list({id})
        const {status, petfood} = data
        if (status == 'ok') {
          setFoods(petfood)
        } else {
          setFoods([])
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }

    if (id) 
      getList(id)
  }, [id])

  const onClickItem = (index: number) => {
    navigate(`/pets/${id}/foods-item`, {
      state: {
        foods: foods,
        index: index
      }
    })
  }

  return (<>
    <Wrapper>
      <div className="header">
        <h1>登録ペットフード</h1>
        <p>最大10個までペットフードを登録できます</p>
      </div>
      <div className="list-food">
        {foods.map((el, index) => {
          return (
            <Item
              key={index}
              index={index+1}
              item={el}
              totalCount={foods.length}
              onClick={() => onClickItem(index)}
            />
          )
        })}
      </div>
      <LinkGroup petId={id} />
    </Wrapper>
  </>)
}

export default FoodSupport5

const Item = ({
  index,
  item,
  totalCount,
  onClick,
}: any) => {

  return (<>
    <ItemWrapper>
      <figure onClick={onClick}>
        <img src={item?.imageSrc ?? '/noimg.png'} alt="" />
      </figure>
      <p className="index">{index}/{totalCount}</p>
      <div className="info">
        <div className="info-row">
          <div>
            <label className="label text-sm">メーカー</label>
          </div>
          <div className="value text-sm">{item?.makerName}</div>
        </div>
        <div className="info-row">
          <div>
            <label className="label text-sm">商品名</label>
          </div>
          <div className="value text-sm">{item?.productName}</div>
        </div>
      </div>
    </ItemWrapper>
  </>)
}

const Wrapper = styled.div`
  .header {
    text-align: center;
    padding-top: 20px;
    margin: 0 0 30px;
    background: url(/txt02.png) no-repeat top 14px center;
    background-size: 150px auto;
    h1 {
      font-size: 28px;
      font-weight: bold;
    }
  }
  .list-food {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`
const ItemWrapper = styled.div`
  figure {
    font-size: 0;
    text-align: center;
    margin: 0 auto 3px;
    img {
      width: 180px;
      height: 180px;
      border-radius: 999px;
      object-fit: cover;
      margin: auto;
    }
  }
  p {
    margin-bottom: 5px;
  }
  .info-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    .value {
      flex: 1;
      color: #000;
      padding: 5px 12px;
      background-color: #fff;
    }
  }
  .label {
    display: inline-block;
    width: 90px;
    line-height: 2;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 10px;
  }
`