import styled from "styled-components"
import { QuestionHeader } from "./components"
import { Link, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useAppDispatch } from "../../hooks"
import { clearNewPetData, setNewPetData } from "../../store/appSlice"


const Question2 = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(clearNewPetData())
  }, [])

  const onNext = (data: any) => {
    dispatch(setNewPetData(data))
    navigate('/pets/new/ques3')
  }

  return (<>
    <Wrapper className="relative">
      <QuestionHeader
        index="2"
      >
        <div className="font-bold">
          <p className="leading-5">ペットの種類は？</p>
        </div>
      </QuestionHeader>
      <button
        className="block w-full text-xl text-center rounded-full font-bold bg-green-1 py-3 border"
        onClick={() => onNext({ petTypeId: '1' })}
      >犬</button>
      <button
        className="block w-full text-xl text-center rounded-full font-bold bg-green-1 py-3 border mt-6"
        onClick={() => onNext({ petTypeId: '2' })}
      >猫</button>
      <div className="my-16">
        <img src="/img05.png" alt="" />
      </div>
      {/* <button
        className="btn bg-green"
        onClick={handleSubmit(onNext)}
      >次へ</button> */}
      <button className="btn sky-1 mt-4"
        onClick={() => navigate('/pets/new/ques1')}
      >戻る</button>
    </Wrapper>
  </>)
}

export default Question2

const Wrapper = styled.div`
  
`

