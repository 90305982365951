import styled from "styled-components"
import { useNavigate } from "react-router-dom"

const RegisterComplete = () => {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <h1 className="title">飼い主登録<span>owner</span></h1>
      <p className="text-center text-sm">アカウントの仮登録を受け付けました。<br />
ご登録いただいたメールアドレスに<br />本登録確認用のご案内をお送りしましたので、<br />
メールの内容を確認して、<br />アカウントの本登録を完了してください。</p>
      <button
        className="btn-box bg-blue !mt-10 !h-12"
        onClick={() => navigate('/login')}
      >ログインページに戻る</button>
    </Wrapper>
  )
}

export default RegisterComplete

const Wrapper = styled.div`
  h1 {
    padding: 20px 0;
    span {
      top: 28px;
    }
  }
`
