import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ContactFooter } from '../../components/common';
import { useApp, useAppSelector } from '../../hooks';
import { getAppStates } from '../../store/appSlice';
import { ContactModal } from '../../components/modals';

const OnepointAdvice: React.FC = () => {
  const { isEmptyPet } = useAppSelector(getAppStates)

  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false)

  return (<>
    <Wrapper>
      <div className='relative max-w-60 pb-5 mb-10 border-b mx-auto'>
        <h1 className="relative flex justify-center align-center text-center h-16 font-bold z-0">
          <span className="text-lg leading-5">ワンポイント<br />アドバイス</span>
          <span className="inline-block absolute top-0 left-0 w-full futurabt text-3xl leading-8 text-center sky-2 tracking-widest uppercase z-[-1]">one point <br />advice</span>
        </h1>
        <img src='/img03.png' alt='' className='absolute bottom-0 right-0 w-12' />
      </div>
      <p className='fs-15 mb-9'>現在与えているペットフードや、また今後与えてみたいものを検索より探して登録。<br/>
        検査結果の主原料レベル（色）が原材料に反映されます。</p>
      <div className='mb-16'>
        <div className='mb-12'>
          <div className='relative'>
            <label className='inline-block bg-danger py-1 px-2'>ポイント１</label>
            <img src='/img08.png' alt='' className='absolute right-0 -top-2 h-12' />
          </div>
          <h2 className='text-center mt-5 mb-4'>原材料上位項目がポイント</h2>
          <div className='leading-5'>
            <p>人の食品と同じく、特に加工品となるペットフードにおいては全ての不耐性を取り除くことは１００％不可能ですので、まずは「原材料全ての項目、全ての不耐性」をみるのではなく、フード内の原料で大半を占める「主原料＝タンパク質」ここを一番重要視します。</p>
            <p>フードは、原材料の多い順にて表記がありますので、メーカにより多少異なりますが成分記載の1番目～5番目（メーカーによっては10番目位まで）のたんぱく質源で、レベル3、2が少ないものを選び、消去法で除去をしていきます。</p>
          </div>
        </div>
        <div className='mb-8'>
          <div className='relative'>
            <label className='inline-block bg-danger py-1 px-2'>ポイント2</label>
            <img src='/img09.png' alt='' className='absolute right-0 -top-8 h-20' />
          </div>
          <h2 className='text-center mt-5 mb-4'>該当の項目は1ずつ検証する</h2>
          <div className='leading-5'>
            <p>例えば成分記載で上位にあるAとBのタンパク質がレベル3であった場合、体質影響を及ぼしている項目を特定するたために、まず、Aが含まないフードで様子を見る。</p>
            <p>それでも改善がみられない場合Bが含まないフード・・・というように、何もかもすべてを取り除くではなく、原料の成分量が多い項目から1つ1つ消去法で様子をみて除去をおこなってください。</p>
          </div>
        </div>
        <div className='mb-8'>
          <div className='relative'>
            <label className='inline-block bg-danger py-1 px-2'>ポイント3</label>
            <img src='/img11.png' alt='' className='absolute right-0 -top-8 h-20' />
          </div>
          <h2 className='text-center mt-5 mb-4'>検査反応が異なる可能性について</h2>
          <div className='leading-5'>
            <p>生食、また一部の非加熱製造、特殊製法のペットフード商品においては、たんぱく質の変性（加熱変性）が異なる可能性から不耐性のレベルが合致しない場合があります。</p>
          </div>
        </div>
        <div className='mb-8'>
          <div className='relative'>
            <label className='inline-block bg-danger py-1 px-2'>ポイント4</label>
            <img src='/img12.png' alt='' className='absolute right-0 -top-8 h-20' />
          </div>
          <h2 className='text-center mt-5 mb-4'>プロバイオティクスについて</h2>
          <div className='leading-5'>
            <p>検査項目の各種プロバイオティクスは加熱（死菌）タイプとなります。</p>
            <p>生菌、凍結乾燥粉末などの非加熱製法のプロバイオティクスは同一項目名でも異なる性質となり検査項目対象外となります。</p>
            <p>プロバイオティクスに限らずですが、検査項目に無いものは与えないではなく、腸内環境を整えるプロバイオティクス、プレバイオティクスは状態観察をしながら与えてみましょう。</p>
          </div>
        </div>
        <div className='mb-6'>
          <div className='relative'>
            <label className='inline-block w-full text-center bg-danger py-1 px-2'>フードローテーションについて</label>
          </div>
          <div className='leading-5 mt-4'>
            <p>フードのローテーションは不耐性・アレルゲンの蓄積を避けるため、概ね3ヶ月（90日）を目安に主原料（タンパク質）が異なるものに切り替えることをオススメしております</p>
            <p>（例：Aのフードを3ヶ月、Bのフードに切り替える。3ヶ月後、Cのフードに。　3ヶ月後、最初のAのフードに…）不耐性はレベル3，2であっても、先天性であるか後天性であるかにより多少の違いはありますが、90日程度抜くことでレベル3がレベル2，1に落ちるなどの報告も多く寄せられております。レベル2，3が出たものは今後与えない。ではなく、一定の期間お休みをしてまた再開してみてください。</p>
            <p>ただし、レベルの変化には体調、ホルモンバランスなどに個体差があり、ローテーションの適切なタイミングは体調を見ながらおこなう事が重要であり、早い場合は2週間～3週間程度で不耐性に変化が出る場合がありますのでお気をつけください。</p>
            <br />
            <p>お困りの際はお気軽にお問い合わせください。</p>
          </div>
        </div>
      </div>
      <div className='w-64 mx-auto'>
        <button
          className="btn bg-blue-1 mb-3 !h-9"
          onClick={() => navigate(isEmptyPet ? '/pets/new/ques1' : '/pets/list')}
        >マイページ</button>
        <ContactFooter
          className='!text-white'
        />
      </div>
      <div className='text-right mt-12 -mb-[35px] fs-0'>
        <button 
          className='inline-block'
          onClick={() => setIsOpen(true)}
        >
          <img src='/img02.png' alt='' className='w-40' />
        </button>
      </div>
      <ContactModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </Wrapper>
  </>)
};

export default OnepointAdvice;

const Wrapper = styled.div`
  
`
