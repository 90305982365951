import styled from "styled-components"
import { QuestionHeader } from "./components"
import { Link, useNavigate } from "react-router-dom"
import { DateSelector } from "../../components/common"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { object, string } from "yup"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { getAppStates, setNewPetData } from "../../store/appSlice"
import { useEffect } from "react"

const schema = object().shape({
  birthDate: string().required('生年月日を入力してください'),
})

const Question7 = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { newPetData } = useAppSelector(getAppStates)

  const {
    reset,
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      birthDate: '',
    }
  })

  useEffect(() => {
    reset({
      birthDate: newPetData.birthDate
    })
  }, [])

  const onNext = (values: any) => {
    dispatch(setNewPetData(values))
    navigate('/pets/new/ques8')
  }

  return (<>
    <Wrapper className="flex flex-col flex-1">
      <div className="flex-1 mb-10">
        <QuestionHeader
          index="7"
        >
          <div className="font-bold">
            <p className="leading-5">{newPetData.name ?? 'ペット'}の<br />お誕生日は？</p>
          </div>
        </QuestionHeader>
        <div>
          <figure className="mb-6">
            <img src="/img07.png" className="w-40 m-auto" alt="" />
          </figure>
          <DateSelector
            name="birthDate"
            control={control}
          />
          <p className="text-center">正確な誕生日が分からない場合は、<br />おおよその生年月日をご入力ください。</p>
        </div>
      </div>
      <button
        className="btn bg-green"
        onClick={handleSubmit(onNext)}
      >次へ</button>
      <button className="btn sky-1 mt-4"
        onClick={() => navigate('/pets/new/ques6')}
      >戻る</button>
    </Wrapper>
  </>)
}

export default Question7

const Wrapper = styled.div`
  
`

