import classNames from "classnames"
import { useEffect, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import styled from "styled-components"
import { useApp } from "../../hooks"
import { PetfoodService } from "../../api"
import dayjs from "dayjs"

const FoodSupport4 = () => {
  const {id} = useParams()
  const {state} = useLocation()
  const {setIsLoading} = useApp()

  const [foods, setFoods] = useState(state?.foods ?? [])
  const [index, setIndex] = useState(state?.index ?? 0)

  useEffect(() => {
    const getList = async (id: string) => {
      try {
        setIsLoading(true)
        const {data} = await PetfoodService.list({id})
        const {status, petfood} = data
        if (status == 'ok') {
          setFoods(petfood)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }

    if (id && !foods.length) 
      getList(id)
  }, [id])


  const onRemove = async (index: number) => {
    try {
      setIsLoading(true)
      const {data} = await PetfoodService.remove({
        pid: id,
        fid: foods[index].foodId
      })
      if (data.status == 'ok') {
        setFoods(foods.filter((el: any, i: number) => i !== index))
      }
    } catch (err) {

    } finally {
      setIsLoading(false)
    }
  }

  const onNext = () => {
    window.scrollTo(0, 0)
    setIndex((index+1) % foods.length)
  }

  return (<>
    <Wrapper>
      <Item
        item={foods[index]}
        index={index+1}
        totalCount={foods.length}
        onRemove={() => onRemove(index)}
      />
      <div className="footer">
        <div>
          <label className="lbl-swipe"
            onClick={onNext}
          >次のフードへ<br /><span>SWIPE</span></label>
        </div>
        <Link to="/onepoint-advice" className="btn-onepoint">
          <img src="/img01.png" alt="" />
        </Link>
      </div>
    </Wrapper>
  </>)
}

export default FoodSupport4

const Item = ({
  item,
  index,
  totalCount,
  onRemove,
}: any) => {
  
  return (<>
    <div className="header">
      <span className="index">{index}/{totalCount}</span>
      <div className="figure">
        {!!item?.affiliateTag ? <a href={item.affiliateTag} target="_blank">
          <img src={item?.imageSrc ?? '/noimg.png'} alt="" className="w-[180px] h-[180px] rounded-full object-cover" />
        </a> : <img src={item?.imageSrc ?? '/noimg.png'} alt="" className="w-[180px] h-[180px] rounded-full object-cover" />}
        <button
          className={'btn-toggle-reg on'}
          onClick={onRemove}
        >{'登録解除'}</button>
      </div>
    </div>
    {!!item?.affiliateTag && <>
      <div className="text-center text-[0px] mb-8">
        <a href={item.affiliateTag} target="_blank" className="inline-block">
          <img src="/btn-buy.png" alt="" className="h-14" />
        </a>
      </div>
    </>}
    <div className="info">
      <div className="info-row">
        <div>
          <label className="label text-sm">メーカー</label>
        </div>
        <div className="value text-sm">{item?.makerName}</div>
      </div>
      <div className="info-row">
        <div>
          <label className="label text-sm">商品名</label>
        </div>
        <div className="value text-sm">{item?.productName}</div>
      </div>
    </div>
    <div className="center">
      <label className="label">原材料</label>
    </div>
    <div className="mark"><span>● 要停止</span><span>● 要注意</span><span>● 要観察</span></div>
    <div className="summary-wrapper">
      <div className="summary text-black text-sm mb-5" dangerouslySetInnerHTML={{__html: item?.rawMaterials}}></div>
      <p className="date">最終被毛採取日／{dayjs(item?.lastExtractionDate).format('YYYY年M月D日')}</p>
    </div>
  </>)
}

const Wrapper = styled.div`
  .header {
    position: relative;
    text-align: center;
    margin: 30px 0 40px;
    .index {
      position: absolute;
      top: -10px;
      left: 20px;
    }
    .figure {
      position: relative;
      display: inline-block;
      max-width: 50%;
      font-size: 0;
      .btn-toggle-reg {
        position: absolute;
        bottom: 3px;
        left: calc(100% - 4px);
        width: 80px;
        height: 34px;
        font-family: inherit;
        font-size: 15px;
        color: #fff;
        text-align: center;
        border-radius: 10px;
        background-color: #e60027;
        &.on {
          color: #fff;
          background-color: #bcbcbc;
        }
      }
    }
  }
  .info {
    margin-bottom: 40px;
  }
  .info-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    .value {
      flex: 1;
      color: #000;
      padding: 5px 12px;
      background-color: #fff;
    }
  }
  .label {
    display: inline-block;
    width: 90px;
    line-height: 2;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 10px;
  }
  .mark {
    display: flex;
    justify-content: center;
    gap: 12px;
    font-weight: bold;
    margin: 12px 0 8px;
    span:nth-child(1) {
      color: #e60027
    }
    span:nth-child(2) {
      color: #fff100
    }
    span:nth-child(3) {
      color: #007d32
    }
  }
  .summary-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    padding: 12px 12px 6px;
    background-color: #fff;
    .summary {
      flex: 1;
      .level_1 {
        color: green;
      }
      .level_2 {
        color: orange;
      }
      .level_3 {
        color: red;
      }
    }
    .date {
      font-size: 12px;
      font-weight: bold;
      color: #000;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: -60px -15px -35px 0;
    .lbl-swipe {
      position: relative;
      display: inline-block;
      text-align: center;
      color: #fff;
      line-height: 1;
      padding: 6px 20px;
      margin-bottom: 25px;
      background-color: #000;
      border-radius: 10px;
      &:after {
        content: '';
        position: absolute;
        top: calc(50% - 10px);
        left: calc(100% + 8px);
        width: 28px;
        height: 20px;
        background: url(/icon-double-right.png) no-repeat center;
        background-size: contain;
      }
    }
  }
`
