import classNames from "classnames"
import { Control, Controller } from "react-hook-form"
import styled from "styled-components"

interface Props {
  label?: string,
  name?: string,
  control?: Control<any>,
  options?: Array<any>,
  size?: 'small' | 'default',
  dir?: 'horizontal' | 'vertical',
  readOnly?: boolean,
}

const FormCheckGroup = ({
  label,
  name = 'checkbox',
  control,
  options = [],
  readOnly = false,
}: Props) => {
  return (<>
    <Wrapper className="mb-[14px]">
      {!!label && <label>{label}</label>}
      <Controller
        name={name}
        control={control}
        render={({field: {name, value, onChange}, fieldState}) => {
          const {invalid, error} = fieldState

          return <>
            <div className="flex flex-wrap gap-y-1">
              {options.map((el: any) => {
                return (
                  <div className="flex-[50%]">
                    <label
                      className={classNames({
                        'flex align-center gap-4': true,
                        'pointer-events-none': readOnly,
                      })}
                    >
                      <input
                        type="checkbox"
                        name={name}
                        value={el.value}
                        onChange={(e) => {
                          if (e.target.checked) {
                            onChange([...value, el.value.toString()])
                          } else {
                            onChange(value.filter((v: any) => v != el.value))
                          }
                        }}
                        checked={(value ?? []).includes(el.value.toString())}
                        className={classNames({
                          'pointer-events-none': readOnly,
                          'w-4 h-4 mt-[1px]': true,
                        })}
                      /><span>{el.label}</span>
                    </label>
                  </div>
                )
              })}
            </div>

            {invalid && <span className="text-rose-500 font-bold text-sm">{error?.message}</span>}
          </>
        }}
      />
    </Wrapper>
  </>)
}

export default FormCheckGroup

const Wrapper = styled.div`
`
