import styled from "styled-components"
import { useApp, useAppDispatch, useAppSelector } from "../../hooks"
import { DateSelector, Input, InputPassword, InputTel, InputZipcode, RadioGroup, RegisterStep, Select } from "../../components/common"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { object, string } from "yup"
import { getAppStates, setRegisterData } from "../../store/appSlice"
import { GENDERS, PREFECTURES } from "../../helpers/enums"
import { AuthService } from "../../api"

const schema = object().shape({
  mailaddress: string().email('メール形式で入力してください')
    .required('メールアドレスを入力してください'),
  password: string().required('パスワードは必須です')
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()-_=+]).{6,}$/,
      '半角英数と記号を含む6文字以上のパスワードを入力してください'
    ),
  name: string().required('お名前を入力してください'),
  sex: string().required('性別を選択してください'),
  birthDate: string().required('生年月日を選択してください'),
  postCode: string().required('郵便番号を入力してください')
    .matches(/^\d{7}$/, '郵便番号の形式が正しくありません'),
  postCodeLeft: string().required('郵便番号を入力してください')
    .matches(/^\d{3}$/, '郵便番号の形式が正しくありません'),
  postCodeRight: string().required('郵便番号を入力してください')
    .matches(/^\d{4}$/, '郵便番号の形式が正しくありません'),
  prefecture: string().required('都道府県を選択してください'),
  municipalities: string().required('市区町村を入力してください'),
  houseNumber: string().required('建物名を入力してください'),
  phoneNumber: string().required('電話番号を入力してください')
    .matches(/^\d{11}$/, '電話番号の形式が正しくありません'),
})

const RegisterStep2 = () => {
  const { setIsLoading } = useApp()
  const dispatch = useAppDispatch()
  const { registerData } = useAppSelector(getAppStates)

  const navigate = useNavigate()

  const {
    reset,
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    reset(registerData)
  }, [])

  const onRegister = async (values: any) => {
    try {
      setIsLoading(true)
      const { data } = await AuthService.register(values)
      const { status } = data
      if (status == 'ok') {
        dispatch(setRegisterData(null))
        navigate('/register/complete')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const onBack = () => {
    navigate('/register/step3')
  }

  return (<>
    <Wrapper>
      <h1 className="title">飼い主登録<span>owner</span></h1>
      <RegisterStep index={4} />
      <h2 className="text-2xl	font-bold mt-12 mb-7">Sign UP</h2>
      <form onSubmit={handleSubmit(onRegister)}>
        <Input
          label="メールアドレス"
          type="email"
          name="mailaddress"
          control={control}
          readOnly
        />
        <InputPassword
          label="パスワード"
          name="password"
          control={control}
          readOnly
        />
        <Input
          label="お名前"
          className="mb-14"
          name="name"
          control={control}
          readOnly
        />
        <RadioGroup
          label="性別"
          name="sex"
          control={control}
          options={GENDERS}
          dir={'vertical'}
          readOnly
        />
        <DateSelector
          label="生年月日"
          name="birthDate"
          control={control}
          readOnly
        />
        <InputZipcode
          label="郵便番号"
          name="postCode"
          control={control}
          readOnly
        />
        <Select
          label="都道府県"
          name="prefecture"
          control={control}
          options={PREFECTURES}
          controlClassName="!w-28"
          readOnly
        />
        <Input
          label="市区町村"
          name="municipalities"
          control={control}
          readOnly
        />
        <Input
          label="番地以降"
          name="houseNumber"
          control={control}
          readOnly
        />
        <InputTel
          label="電話番号"
          name="phoneNumber"
          control={control}
          readOnly
        />
        <div className="mt-10">
          <button
            type="submit"
            className="btn bg-green"
          >次へ</button>
          <button
            type="button"
            className="btn sky-1 mt-4"
            onClick={onBack}
          >戻る</button>
        </div>
      </form>
    </Wrapper>
  </>)
}

export default RegisterStep2

const Wrapper = styled.div`
  h1 {
    padding: 20px 0;
    span {
      top: 28px;
    }
  }
`



