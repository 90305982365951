import client from "./client"

const create = (data: any) => {
  return client.post("/test", data)
}

const list = (params: any) => {
  return client.get("/tests", {params})
}

const get = (params: any) => {
  return client.get("/test", {params})
}

const send = (data: any) => {
  return client.post("/test_status", data)
}

export default {
  get,
  list,
  send,
  create,
}
