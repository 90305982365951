import styled from "styled-components"
import { Input } from "../../components/common"
import { Link } from "react-router-dom"
import { object, string } from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useApp, useAppSelector } from "../../hooks"
import { useEffect } from "react"
import { getAppStates } from "../../store/appSlice"
import { ProfilesService } from "../../api"
import toast from "react-hot-toast"

const schema = object().shape({
  mailAddress: string().email('メール形式で入力してください')
    .required('メールアドレスを入力してください'),
})

const ChangeEmail = () => {
  const { user, setIsLoading } = useApp()
  const { isEmptyPet } = useAppSelector(getAppStates)
  
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      mailAddress: ''
    }
  })

  useEffect(() => {
    reset({
      mailAddress: user?.mailAddress ?? ''
    })
  }, [user])

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true)
      const { data } = await ProfilesService.changeEmail(values)
      if (data.status === 'ok') {
        toast.success('メールアドレス変更メールを送信しました。')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (<>
    <Wrapper>
      <h1 className="title">メールアドレスの変更<span>MAIL</span></h1>
      <div>
        <Input
          label="メールアドレス"
          type="email"
          name="mailAddress"
          control={control}
        />
        <div className="txt">
          <p>キャリアメール（docomo/au/softbank等）にメール未着が多発しております。 フリーメール（yahooやgmail等）並びにPCメールの使用を強くお勧めします。</p>
          <p>※キャリアメールの未着については、お客様自身にてキャリア側のセキュリティ設定での対応となります。</p>
        </div>
        <button
          className="btn bg-green"
          onClick={handleSubmit(onSubmit)}
        >確認メールを送信する</button>
        <div className="center link-wrapper">
          <Link to={isEmptyPet ? '/pets/new/ques1' : '/pets/list'} className="link">マイページに戻る</Link>
        </div>
      </div>
    </Wrapper>
  </>)
}

export default ChangeEmail

const Wrapper = styled.div`
  h1 {
    padding: 60px 0 50px;
  }
  .txt {
    font-size: 14px;
    line-height: 20px;
    margin: 16px 0 20px;
  }
  .link-wrapper {
    margin-top: 20px;
    a {
      font-weight: bold;
      color: #00e6fb;
    }
  }
`
