import { Control, Controller } from "react-hook-form"
import styled from "styled-components"

interface Props {
  label?: string,
  name?: string,
  control?: Control<any>,
  options?: Array<any>,
  className?: string,
}

const FormRadioBoxGroup = ({
  label,
  name = 'radio',
  control,
  options = [],
  className = '',
}: Props) => {
  return (<>
    <Wrapper className={`mb-[14px] ${className}`}>
      {!!label && <label>{label}</label>}
      <Controller
        name={name}
        control={control}
        render={({field: {name, value, onChange}, fieldState}) => {
          const { invalid, error } = fieldState
          return <>
            <div className="flex flex-col gap-4">
              {options.map((el: any, index: number) => {
                return (
                  <label
                    key={index}
                    className="flex align-center gap-3 bg-white py-4 px-5"
                  >
                    <input
                      type="radio"
                      name={name}
                      value={el.value}
                      onChange={onChange}
                      checked={value == el.value}
                      className="w-4 h-4 mt-[1px]"
                    /><span className="gray-1">{el.label}</span>
                  </label>
                )
              })}
            </div>
            {invalid && <span className="text-rose-500 font-bold text-sm">{error?.message}</span>}
          </>
        }}
      />
    </Wrapper>
  </>)
}

export default FormRadioBoxGroup

const Wrapper = styled.div`
`
