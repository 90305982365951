import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "../../../hooks"
import { getAppStates } from "../../../store/appSlice"

interface Props {
  disabled?: boolean,
}

const BtnNewFood = ({
  disabled = false,
}: Props) => {
  const navigate = useNavigate()
  const { selectedPet } = useAppSelector(getAppStates)

  return (<>
    <button
      className={classNames({
        "flex align-center justify-center w-80 h-16 py-3 px-4 rounded-xl m-auto bg-sky": true,
        'bg-gray-1': disabled,
      })}
      onClick={() => navigate(`/pets/${selectedPet?.id}/foods`)}
      disabled={disabled}
    >
      <p className="text-2xl font-bold leading-none">登録ペットフード確認</p>
    </button>
  </>)
}

export default BtnNewFood
