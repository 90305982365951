import styled from "styled-components"
import { BtnConfirmSearch, BtnFoodSupport, BtnNewApplicate, BtnNewFood } from "../../components/common/buttons"
import { Link, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { PetsService } from "../../api"
import { useApp, useAppDispatch } from "../../hooks"
import { setSelectedPet } from "../../store/appSlice"

const Top = () => {
  const {id} = useParams()
  const dispatch = useAppDispatch()
  const {setIsLoading} = useApp()
  const [pet, setPet] = useState<any>(null)
  const [petfoodCount, setPetfoodCount] = useState(0)

  useEffect(() => {
    const getPet = async (id: string) => {
      try {
        setIsLoading(true)
        const {data} = await PetsService.get({id})
        const { status, petDetail } = data
        if (status == 'ok') {
          setPet(petDetail)
          dispatch(setSelectedPet(petDetail))
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }

    const getFoodCount = async (id: string) => {
      const {data} = await PetsService.getFoodCount({id})
      const {status, petfoodCount} = data
      if (status == 'ok') {
        setPetfoodCount(petfoodCount)
      }
    }
    
    if (id) {
      getPet(id)
      getFoodCount(id)
    }
  }, [id])

  return (<>
		<Wrapper>
      {pet && <>
        <h1 className="relative flex justify-center align-center pt-3 z-0 mt-2">
          <span className="text-2xl mr-2 min-h-8">{pet?.name}さん</span>
          <span className="inline-block absolute -top-1 left-0 w-full futurabt text-3xl text-center sky-2 tracking-widest uppercase z-[-1]">data</span>
        </h1>
        <figure className="mt-4 mb-5">
          <img src={pet?.imageUrl ?? '/noimg.png'} alt="" className="w-36 h-36 object-cover rounded-full bg-white m-auto" /> 
        </figure>
        <div className="w-80 m-auto">
          <BtnNewApplicate />
          <p className="my-3 text-[15px] text-center">同じ子で複数回の検査をする際もこちら<br />過去の検査履歴も残ります</p>
          <BtnFoodSupport
            petId={id}
            disabled={petfoodCount >= 10}
          />
          <p className="my-3 text-[15px] text-center">検査前、検査後の登録変更が可能<br />
            検査結果から登録したペットフードの<br />
            適正を判断したり、主原料から<br />
            ペットフードを探す事が可能です</p>
          <BtnNewFood
            disabled={!pet?.testCount}
          />
          <div className="text-[15px] mt-3 mb-6">
            <p className="text-center">最大10個までペットフードを登録できます</p>
            {petfoodCount >= 10 && <>
              <p className="text-[15px] text-[#e60027]">登録数が10に達しています。新しく登録する場合は既存の商品を解除ください</p>
            </>}
          </div>
          <BtnConfirmSearch
            disabled={!pet?.testCount}
          />
        </div>
        <div className="text-center mt-10">
          <Link to={`/pets/list`} className="inline-block max-w-[260px]">
            <img src="/btn-pets.png" alt="" className="max-w-full" />
          </Link>
        </div>
      </>}
		</Wrapper>	
	</>)
}

export default Top

const Wrapper = styled.div`
	
`
