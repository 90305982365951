import client from "./client"

const update = (data: any) => {
  return client.put("/put_account", data)
}

const changeEmail = (data: any) => {
  return client.put("/put_mailaddress", data)
}

const verifyChangeEmail = (params: any) => {
  return client.get("/mail_address_register/verify", { params })
}

const changePassword = (data: any) => {
  return client.put("/put_password", data)
}

export default {
  update,
  changeEmail,
  verifyChangeEmail,
  changePassword,
}
