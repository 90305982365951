import { CSSProperties } from "react"
import { Control, Controller } from "react-hook-form"
import styled from "styled-components"

interface Props {
  label?: string,
  rows?: number,
  name?: string,
  control?: Control<any>,
  readOnly?: boolean,
  controlClassName?: string,
}

const FormTextarea = ({
  label,
  control,
  rows = 5,
  name = 'textarea',
  readOnly = false,
  controlClassName = '',
}: Props) => {
  return (<>
    <Wrapper>
      {!!label && <label>{label}</label>}
      <div
        className=""
        style={{fontSize: 0}}
      >
        <Controller
          name={name}
          control={control}
          render={({field, fieldState}) => {
            const { error, invalid } = fieldState

            return <>
              <textarea
                {...field}
                rows={rows}
                className={`text-base ${controlClassName}`}
                readOnly={readOnly}
              ></textarea>
              {invalid && <span className="text-rose-500 font-bold text-sm">{error?.message}</span>}
            </>
          }}
        />
      </div>
    </Wrapper>
  </>)
}

export default FormTextarea

const Wrapper = styled.div`
  margin-bottom: 14px;
  label {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 5px;
  }
  textarea {
    width: 100%;
    padding: 10px 15px;
    color: #000;
    background-color: #fff;
    border: none;
    outline: none;
  }
`
