import styled from "styled-components"
import { QuestionHeader } from "./components"
import { Link, useNavigate } from "react-router-dom"
import { Select } from "../../components/common"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { object, string } from "yup"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { getAppStates, setNewPetData } from "../../store/appSlice"
import { useEffect, useMemo } from "react"

const schema = object().shape({
  bodyWeight: string().required('体重を入力してください'),
})

const Question6 = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { newPetData } = useAppSelector(getAppStates)

  const weightOptions = useMemo(() => {
    // 0から100kgまで0.5kg単位で体重を設定
    const options = []
    for (let i = 1; i <= 200; i++) {
      options.push({
        value: i / 2,
        label: `${i / 2}kg`
      })
    }
    return options
  }, [])

  const {
    reset,
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      bodyWeight: '',
    }
  })

  useEffect(() => {
    if (newPetData.petBreedList) {
      reset({
        bodyWeight: newPetData.bodyWeight
      })
    }
  }, [])

  const onNext = (values: any) => {
    dispatch(setNewPetData(values))
    navigate('/pets/new/ques7')
  }

  return (<>
    <Wrapper className="flex flex-col flex-1">
      <div className="flex-1 mb-5">
        <QuestionHeader
          index="6"
        >
          <div className="font-bold">
            <p className="leading-5">{newPetData.name ?? 'ペット'}の<br />体重を教えてください</p>
          </div>
        </QuestionHeader>
        <figure className="text-center">
          <img src="/img06.png" className="w-32 m-auto" alt="" />
        </figure>
        <Select
          name="bodyWeight"
          control={control}
          options={weightOptions}
          controlClassName="h-12 !text-black text-center mt-8"
        />
      </div>
      <button
        className="btn bg-green"
        onClick={handleSubmit(onNext)}
      >次へ</button>
      <button className="btn sky-1 mt-4"
        onClick={() => navigate('/pets/new/ques5')}
      >戻る</button>
    </Wrapper>
  </>)
}

export default Question6

const Wrapper = styled.div`
  
`

