import styled from "styled-components"

const ServicePolicyText =
  "利用規約\n\
第1条（目的）\n\
この規約(以下「本規約」といいます。)は、利用者ならびに株式会社ファイブストランドジャパン(以下「当社」といいます)との間の、本サービスの利用等に関する契約(以下「本契約」といいます。)の成立及び内容等について定めることを目的とします。\n\
\n\
第2条（用語の定義）\n\
本規約において以下の各号の用語は、本規約に別段の定めがある場合を除き、当該各号記載の意味を有するものとします。\n\
\n\
1. 「利用者」とは、本規約に同意の上、本サービスを利用する個人をいいます。\n\
2. 「本サービス」とは、利用者との間で行われる当社サービスその他別途当社が定める機能を本システムを通じて利用できるサービスを指します。\n\
3. 「本システム」とは、当社が提供する飼育ペットの検索、検査結果の表示およびフードの検索をいいます。\n\
4. 「サービスサイト」とは、当社が本サービスを提供するためのウェブサイト及びその他電子媒体をいいます。\n\
\n\
第3条（本契約の成立）\n\
1. 利用者が本規約に同意する旨送信し、当社が当該通知を受領した場合、利用者と当社との間に、当該受領日に本規約の記載事項を契約内容として本契約が成立するものとします。\n\
2. 当社は、利用者に対して、利用者が本規約を遵守することを条件として本サービスを提供し、利用者は、本規約に従ってのみ本サービスを利用することができます。\n\
\n\
第4条（会員登録）\n\
1. 本サービスにおいて利用者が会員登録をする場合、利用者は、会員登録に際して当社が定める氏名・住所等の各必要項目に虚偽のない入力を行い、ID及びパスワードを取得するものとします。会員登録費用、年会費及びサービス利用料等は全て無料です。\n\
2. 当社は、単独かつ完全な裁量により、会員登録を承認し、又は拒否することができます。会員登録を拒否した場合、当社は、利用者に対してその理由を開示する義務を負わないものとします。\n\
3. 会員登録情報の変更、追加が生じた場合、利用者は自己の責任において速やかに当社の定める変更手続きを行うものとします。登録内容の変更等がなされなかったことにより生じた損害については、全て当該利用者が負うものとし、当社は一切の責任を負いません。\n\
4. 利用者は、当社が発行したID及びパスワードを、善良な管理者の注意を持って適切に管理しなければならず、また、これを第三者に開示・利用させたり、貸与、譲渡、売買、担保提供等をしてはならないものとします。当該ID及びパスワードが利用者以外の者によって正しく入力されたとき、その者が利用者本人であるとみなされ、当該ID及びパスワードを用いて行われたすべての結果又は責任は利用者に帰属します。利用者によるID及びパスワードの管理不十分、使用上の過誤、不正使用等によって利用者が損害を被ったとしても、当社は一切責任を負わないものとします。\n\
5. 会員登録情報の変更、追加が生じた場合、利用者は自己の責任において速やかに当社の定める変更手続きを行うものとします。登録内容の変更等がなされなかったことにより生じた損害については、全て当該利用者が負うものとし、当社は一切の責任を負いません。\n\
\n\
第5条（情報の利用について）\n\
利用者は、当サイトを利用することによって得た情報を、利用者自身の判断と責任において利用するものとします。\n\
\n\
第6条（禁止事項）\n\
1. 当社は、本サービスの利用者に対し、以下に該当するまたはそのおそれがある不適切な行為を禁止いたします。但しこれに限るものではありません。\n\
(1) 本規約に反する行為\n\
(2) 第三者に成りすます行為\n\
(3) 本サービスを不正に使用又は使用させる行為\n\
(4) 本サービスの運営を妨げる行為、又はそのおそれのある行為\n\
(5) 本サービスに対して改変、変更、改修、リバースエンジニアリング、分解、デコンパイル、逆アセンブラその他本サービスを解析する行為、又は本サービスから得られるソースコードやソフトウェア等を当社の書面による承諾なしに無断で使用する行為\n\
(6) 本サービスに関し、コンピューターウィルス等の有害なプログラムを使用又は提供する行為\n\
(7) 本サービスに関する設備等に不正にアクセスし、蓄積された情報を不正に書き換え又は消去する行為\n\
(8) 当社若しくは第三者に損害その他の不利益を与える行為、又はそれらのおそれのある行為\n\
(9) 当社又は第三者の権利又は法律上の利益(著作権、特許権、営業秘密、財産権、名誉権、プライバシー権等を含むがこれに限られない)を侵害する又は侵害するおそれのある行為\n\
(10) 当社、他の利用者又は第三者を誹謗中傷し、信用を毀損する行為\n\
(11) 他の利用者の個人情報を収集したり、蓄積すること、又はこれらの行為をしようとする行為\n\
(12) 公序良俗、法令その他の一般常識に反する行為、又はそのおそれのある行為\n\
(13) その他、当社が不適切であると判断する行為\n\
2. 当社は、前項に定める禁止事項を発見した場合、当該利用者に対し事前に通知をすることなく、以下の措置を講じることができるものとします。\n\
① 利用者による本サービスの利用の停止又は本サービスの利用者としての登録の抹消\n\
② 利用者が本サービスにおいて登録した情報の全部又は一部についての変更又は削除\n\
③ その他当社が必要と認める措置\n\
\n\
第7条（本サービスの提供停止）\n\
当社は、以下の各号のいずれかに該当する事由が生じたと当社が判断した場合、利用者への事前の通知又は承諾を要せず、利用者に対する本サービスの全部又は一部の提供を停止することができるものとします。\n\
\n\
1. 定期的又は緊急の保守作業を行う場合\n\
2. ハードウェア又はソフトウェアの交換又はバージョンアップを行う場合\n\
3. コンピュータウイルス、不正アクセス等への対策の実施、コンピュータシステムの不具合の解消作業の実施その他当該コンピュータシステムの円滑な稼働を確保するためにやむを得ない場合\n\
4. 天災、地変、火災、停電、戦争、変乱、暴動、騒乱、労働争議等の非常事態が発生し、又は発生するおそれがある場合\n\
5. 本サービスを利用者に提供するために当社が利用する関連事業者の設備の不具合等、やむを得ない事由が生じた場合\n\
6. 本サービスを利用者に提供するために当社が利用する関連事業者が役務の提供を停止した場合\n\
7. その他、当社が本サービスの提供の全部または一部を中止することが必要であると判断した場合\n\
\n\
第8条（本サービスの変更）\n\
当社は、利用者の事前の承諾なしに本サービスの内容を自由に変更することができるものとします。\n\
\n\
第9条（第三者への委託）\n\
当社は、本規約に基づく当社の業務の全部又は一部を第三者に委託(請負及び委任を含み、以下同じです)することができるものとします。\n\
\n\
第10条（知的財産権）\n\
1. 本システム又は本サービスに関連する文章、イメージその他一切の著作物等の著作権、商標、商号又は標章その他知的財産権は当社又は第三者に帰属するものとし、利用者は、当社の書面による同意なしに複写、複製、送信又は改変その他当社又は第三者の著作権その他の知的財産権を侵害する行為を行ってはなりません。\n\
2. 利用者が本サービスに関して当社に対し情報提供、コメント、その他の提案(以下「フィードバック」といいます)を何らかの形で行った場合、当社は、そのフィードバックに関してすべての権利と利益を得るものとし、そのフィードバックを制限なく自由に使うことができるものとします。\n\
3. 利用者は、本サービスの利用期間中及び本契約終了後も本サービスについて、当社又は第三者に対し、特許侵害その他知的財産権の侵害を申し立て、若しくはその権限を第三者に与え、又は第三者をもって申し立てを行ってはなりません。\n\
\n\
第11条（個人情報管理、取得情報の取り扱い）\n\
1. 当社は、本サービスを提供する上で取得した利用者の個人情報について、当社の定める「個人情報保護方針」に従い取り扱うものとし、利用者はこれに同意します。\n\
2. 当社は、個人情報を含まない限りにおいて、利用者の登録情報や本サービス提供に関連して取得した情報を、あらゆるものに二次利用することができるものとします。これらの情報に関わる知的財産権は当社が保有するものとします。\n\
3. 当社は、利用者からお預かりする情報を、マーケティング上のデータ集計や、新サービスの企画・開発、サイトからの広告・告知、その他、当社サービスの向上に資する目的に利用することがあります。\n\
\n\
第12条（本規約の変更）\n\
1. 当社は、利用者の承諾を得ることなく、本規約の内容を変更することができるものとします。この場合、当社は、本規約変更後、すみやかに利用者から届出のあった通知先(電子メールアドレス若しくは所在地)にその内容を通知するか、又は、インターネット上の当社Webサイト上に変更後の規約をアップロードするものとします。\n\
2. 当社が本規約を変更した場合には、当社が別途定める場合を除き、当社が前項の通知を発信した時点、又は、変更後の規約がインターネット上の当社Webサイト上にアップロードされた時点のいずれか先に手続が完了した時点で当該規約が効力を生じるものとします。\n\
3. 本規約の変更後も引き続き利用者が本契約を継続する場合、本規約の変更に同意したものとみなします。\n\
\n\
第13条（利用者による補償、当社の免責）\n\
1. 利用者は、本サービスを利用するために必要な機器、ソフトウェア、インターネット接続業者との契約、セキュリティーの確保等全てを自己の責任と費用において準備するとともに、これらの諸設定を適切に行うものとします。また、回線利用契約の締結、インターネット接続サービスへの加入、通信機器等の正常な作動の維持等について、自己の費用と責任において行うものとします。当社は、利用者が本サービスを利用するためのネットワーク通信を行うことができる動作環境にあることを何ら保証するものではなく、利用者が用いた通信機器、ソフトウェア、その他これらに付随して必要となる全ての機器、電気通信回線、インターネット接続サービスなどの不具合等によって、利用者が本サービスを受けられなかったとしても、当社は何らの責任も負いません。\n\
2. 利用者は自己の責任において本サービスを利用するものとし、本サービスの利用に関し問題が発生した場合、利用者は、自己の責任と費用において係る問題を解決するとともに、当社に何らの迷惑又は損害を与えないものとします。\n\
3. 本サービスに関連し、利用者もしくは第三者との間で紛争等が発生し、又は、利用者又が相手方若しくは第三者から何らかの請求がなされ、若しくは訴訟が提起された場合、当該利用者又は、自らの費用と責任において当該紛争、請求又は訴訟を解決するものとし、当社に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。これらの紛争によって当社が何らかの損害を被った場合、利用者がその損害の一切を補償するものとします。\n\
4. 本条前各項の場合の他、本規約、本サービスの利用に関連して第三者から当社に対し裁判上又は裁判外の請求がなされたことによって当社が何らかの損失、損害等を被った場合、その他本サービスに関連して当社に損害を与えた場合、当社に故意又は重大な過失がある場合を除き、利用者はこれを全て補償し、当社にいかなる負担も負わせないものとする。\n\
5. 当社は、本サービスの利用に関し、以下のことを保証しないものとします。\n\
(1) 本サービスが中断しないこと\n\
(2) 本サービスにエラー、バグ、不具合、セキュリティ上の欠陥が存在しないこと\n\
(3) 完全、正確、確実、有用であること\n\
(4) 利用者の特定の目的に適合すること\n\
(5) 本サービスの利用に関し通信回線等利用環境に不具合や障害が生じないこと\n\
(6) サービスサイト等上のコンテンツに関する盗用、毀損又は改ざんがないこと\n\
(7) サービスサイト等に対する不正アクセス・ハッキング等のサイバー攻撃がないこと\n\
(8) 当社が提供・表示・通知するすべての情報についての、正確性、完全性、有用性、最新性\n\
6. 当社は、メンテナンス等のために、利用者に通知することなく、本サービスを停止し、または変更することがあります。\n\
7. 当社は、次に該当する場合には利用者に対し事前に連絡することなくサイトを一時的に閉鎖し、または本サービスを一時的に中断する場合があります。\n\
(1) 運用のシステムの保守、点検、修理、変更を定期的にまたは緊急に行なう場合\n\
(2) 火災、停電などにより提供ができなくなった場合\n\
(3) 地震、噴火、洪水、津波などの天災により提供ができなくなった場合\n\
(4) 戦争、変乱、暴動、騒乱、労働争議などにより提供ができなくなった場合\n\
(5) その他、当社が運用上、技術上一時的な閉鎖や中断を必要と判断した場合\n\
8. 当社は、前２項の事由に起因する利用者または他の第三者が蒙った損害について一切の責任を負わないものとします。\n\
9. 当社は、本サービスの変更、終了、停止、利用不能、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他直接的・間接的な理由に関わらず、当社に故意又は重大な過失がある場合を除き、本サービスの利用に関連して利用者が被った、又は被る可能性のある一切の損害について責任を負わないものとします。\n\
10. 当社は、利用者による本サービスの利用により、利用者もしくは利用者の飼育するペットの疾病・疾患が治癒し、その他利用者の希望に適う効果を保証するものではありません。\n\
\n\
第14条（損害賠償）\n\
利用者及び当社は、各自、本規約への違反その他相手方の責めに帰すべき事由によって損害を受けた場合であって、本規約に従い、当該相手方に対し、当該損害の賠償を請求することができるときであっても、当該損害の発生について当該相手方の故意又は重大な過失がある場合を除き、当該損害のうち現実かつ直接に被った通常の損害(逸失利益相当分は含まれません)についてのみ、賠償を請求することができるものとします。\n\
\n\
第15条（解除）\n\
当社は、利用者が本規約に違反し又は違反するおそれがあると認めた場合において、本契約の全部又は一部を解除することができます。\n\
前項に基づく解除も過去には遡及せず、将来に向かってのみ本契約を失効させるものとし、かつ解除の相手方に対する損害賠償の請求を妨げないものとします。\n\
\n\
第16条（退会）\n\
1. 利用者が、いつでも退会することで本契約を終了することができるものとします。本契約を終了する場合は、当社所定の方法により利用者自身で退会の手続きをするものとし、当社が確認したことをもって登録利用者が退会した(本契約が解約された)ものとします。\n\
2. 利用者の資格は、本人のみ有効とし、当社は利用者の死亡を知り得た時点をもって、前項の手続きがあったものとして取り扱います。\n\
3. 利用者によるID・パスワードの紛失、その他利用者に帰すべき原因により、退会手続きができない場合であっても、当社は退会のために特別に対応しません。\n\
\n\
第17条（反社会的勢力の排除）\n\
1. 利用者及び当社は、自己が以下の各号のいずれにも該当しないこと、及び将来にわたってもこれに該当しないことを、相手方に対し表明・保証します。\n\
(1) 暴力団、暴力団構成員、暴力団準構成員、暴力団関係企業、総会屋、社会運動標榜ゴロ、政治活動標榜ゴロ、特殊知能暴力集団その他これらに準ずる集団又は個人(以下「反社会的勢力」という)であること、又は反社会的勢力であったこと\n\
(2) 役員又は実質的に経営を支配する者が反社会的勢力であること、又は反社会的勢力であったこと\n\
(3) 反社会的勢力に自己の名義を利用させ、本契約を締結すること\n\
(4) 反社会的勢力と社会的に非難されるべき関係を有すること\n\
2. 利用者及び当社は、自ら以下の各号に該当する行為を行わないことを確約します。\n\
(1) 脅迫的な言動をすること、若しくは暴力を用いること\n\
(2) 偽計又は威力を用いて業務を妨害し、又は名誉・信用を棄損すること\n\
(3) 法的責任を超えた不当な要求をすること\n\
(4) 自ら又はその役員若しくは実質的に経営を支配する者が反社会的勢力への資金提供を行う等、その活動を助長する行為を行うこと\n\
(5) 前各号に準ずる行為を行うこと\n\
(6) 第三者をして前各号のいずれかに該当する行為を行わせること\n\
3. 利用者及び当社は、第1項各号のいずれかに該当し、若しくは前項各号のいずれかに該当する行為をし、又は第1項の規定に基づく表明・保証に関して虚偽の申告をしたことが判明した場合には、直ちに相手方に通知するものとします。\n\
4. 利用者及び当社は、相手方が第1項各号のいずれかに該当し、若しくは前項各号のいずれかに該当する行為をし、又は第1項の規定に基づく表明・保証に関して虚偽の申告をしたことが判明した場合には、別段の催告を要せず即時に本契約の全部又は一部を解除することができ、また相手方に通知することにより直ちに相手方に対する一切の債務の期限の利益を喪失させることができるものとします。\n\
5. 利用者及び当社は、前各項に違反して相手方に損害を与えた場合、相手方に対し、その損害を賠償しなければなりません。\n\
6. 利用者及び当社は、第4項により本契約を解除されたことを理由として、相手方に対し損害の賠償を請求することができません。\n\
7. 本条の規定は、本規約に定める損害賠償に関する規定に優先して適用します。\n\
\n\
第18条（秘密保持等）\n\
1. 利用者及び当社は、各自、以下の各号のいずれか一つに該当する場合を除き、本契約の締結又は履行に関連して取得した一切の情報(開示の状況から客観的かつ合理的に秘密と認識できる情報に限ります。以下「本情報」と総称します)を秘密として保持し、第三者に開示し、提供し又は漏洩してはなりません。\n\
(1) 事前に相手方から書面による同意を得た場合\n\
(2) 本サービスの提供に必然的に伴う場合\n\
(3) 本規約上許容される自己の業務の委託に必要不可欠な範囲で当該委託に係る委託先に開示し又は提供する場合\n\
(4) 当社「個人情報保護方針」に従い個人情報を共同利用目的で利用する場合\n\
(5) 弁護士、公認会計士、税理士等の法令上の守秘義務を負う専門家への本規約又は本サービスに関連した相談、依頼等に伴って当該専門家に開示する場合\n\
(6) 法令又は証券取引所規程に基づく場合\n\
2. 利用者及び当社は、各自、前項第1号又は第4号に基づいて本情報を第三者に開示する場合には、当該第三者に対して本条に基づく自己の義務と同等の義務を予め課すものとします。\n\
3. 利用者及び当社は、各自、本規約の履行(本サービスの向上及び本規約上許容される委託を行うことを含みます)以外の目的で本情報を利用(複製を含みます)し又は使用してはなりません。但し、当社は、本サービス以外の当社の商品又は当社の関連会社若しくは提携先の商品を利用者に紹介する目的及び本サービス以外の当社の商品を利用者に提供する目的並びに当社のホームページに掲げる個人情報保護方針等において定められている目的(将来変更された場合はその変更後のもの)のいずれかのために利用者に関する本情報を利用することができるものとし、かつ第1項第1号、第3号、第4号及び第5号の除外事由は本項による利用又は使用の制限に関して準用するものとします。\n\
4. 利用者及び当社は、各自、本情報の漏洩、滅失又は毀損の防止その他本情報の安全管理を図るために必要かつ適切な措置を講じるものとします。\n\
5. 以下の各号のいずれか一つに該当した本情報については、当該該当の時以降、前五項は適用しません。但し、当該本情報が個人情報に該当する場合はこの限りでなく、なお前五項が適用されるものとします。\n\
(1) 取得時に既に公知であった場合又は取得後に自己の責めに帰すべき事由に基づかずに公知となった場合\n\
(2) 第三者から秘密保持義務を負うことなく正当な手段で取得した情報と同一内容の場合\n\
(3) 開示を受けた時点で既に適法に保有していた情報\n\
(4) 本情報に依拠せずに自ら独自に開発、創作等した情報と同一内容の場合\n\
\n\
第19条（通知）\n\
1. 当社から利用者への通知は、書面の送付、電子メールの送信、又は本サービス若しくはサービスサイトへの掲載等、当社が適当と判断する手段によって行います。当該通知が、電子メールの送信又は本サービス若しくはサービスサイトへの掲載によって行われる場合は、インターネット上に配信された時点で利用者に到達したものとします。\n\
2. 利用者は、当社から利用者宛に送信される電子メールの受信を拒否する設定等を行ってはならないものとし、受信拒否設定や利用者のメールアドレスが変更された等の事情により、当該利用者に配信されたメールがエラー等により不着であった場合でも、当社からの通知の発信時をもって通知がなされたものとみなします。\n\
3. 利用者から当社への通知は、当社所定の問合せフォームから、又は問合せ用メールアドレス宛に行うものとします。当社は、問合せフォーム又は問合せ用メールアドレス以外からの通知については、対応することができません。\n\
\n\
第20条（地位譲渡）\n\
1. 利用者は、当社の書面による事前の承諾なく、本契約に基づく権利又は義務の全部又は一部につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることや、第三者に相続させることはできません。\n\
2. 当社が本サービスにかかる事業を第三者に譲渡する場合には、利用者の承諾を得ることなく、当該事業譲渡に伴い、本契約に基づく権利及び義務並びに利用者の登録情報その他の顧客情報等を含む本契約上の地位を当該事業譲渡の譲受人に譲渡することができるものとします。なお、このことは、事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合においても同様とします。\n\
\n\
第21条（準拠法、裁判管轄）\n\
本規約の準拠法は日本法とし、利用者と当サイトの間で当サイトに関する訴訟が生じた場合は、福岡地方裁判所を第一審の専属的合意裁判所とします。\n\
\n\
【関連】\n\
・個人情報保護方針\n\
\n\
\n\
2022年12月制定\n\
株式会社ファイブストランドジャパン 代表取締役\n\
代表取締役　渡辺和貴\n\
";

const Terms = () => {
  return (<>
    <Wrapper className="pt-12">
      <h1 className="flex justify-center align-center font-bold mb-8">利用規約</h1>
      <LongText>{ServicePolicyText}</LongText>
      <div className="text-center mt-6">
        <button
          onClick={() => {
            window.history.back();
          }}
        >戻る</button>
      </div>
    </Wrapper>
  </>)
}

export default Terms

const Wrapper = styled.div`
`

const LongText = styled.div`
  font-size: 1rem;
  white-space: pre-wrap;
`;
