import classNames from "classnames"
import styled from "styled-components"
import { ContactFooter, Header } from "../common"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { object, string } from "yup"
import { forwardRef, useEffect, useState } from "react"
import dayjs from "dayjs"
import { leadingZero } from "../../helpers/functions"
import { AuthService } from "../../api"
import { useApp } from "../../hooks"
import toast from "react-hot-toast"

interface Props {
  isOpen: boolean,
  onClose: () => void,
}

const schema = object().shape({
  mail: string().email('メールアドレスの形式が正しくありません。')
    .required('メールアドレスは必須です。'),
  birthday: string().required('生年月日は必須です。'),
})

const defaultValues = {
  mail: '',
  birthday: '',
}

const PasswordForgotModal = ({
  isOpen,
  onClose
}: Props) => {
  const {setIsLoading} = useApp()

  const {
    reset,
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues)
    }
  }, [isOpen])

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true)
      const { data } = await AuthService.forgotPassword(values)
      const { status } = data
      if (status === 'ok') {
        toast.success('パスワード再設定メールを送信しました。')
        onClose()
      }
    } catch (err: any) {
      const { data } = err
      if (data?.messageJp) {
        toast.error(data.messageJp)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (<>
    <Wrapper
      className={classNames({
        'menu-overlay': true,
        'show': isOpen
      })}
    >
      <div className="w-[90%] m-auto">
        <Header />
      </div>
      <div className="inner !pt-2">
        <div className="menu-wrapper">
          <div className="menu-body">
            <button
              className="btn-close z-10"
              onClick={onClose}
            >
              <img src="/icon-close.png" alt="" />
            </button>
            <hr />
            <div>
              <h3 className="text-center font-bold mt-6 mb-4 sky-2">パスワードの再設定メール送信</h3>
              <div className="mb-3">
                <label className="inline-block text-sm text-black mb-1">登録済みのメールアドレス</label>
                <Controller
                  name="mail"
                  control={control}
                  render={({ field, fieldState }) => {
                    const { invalid, error } = fieldState
                    return (<>
                      <input
                        type="email"
                        className="input"
                        {...field}
                      />
                      {invalid && <p className="text-red-500 text-xs mt-1">{error?.message}</p>}
                    </>)
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="inline-block text-sm text-black mb-1">生年月日</label>
                <Controller
                  name="birthday"
                  control={control}
                  render={({ field, fieldState }) => {
                    const {invalid, error} = fieldState
                    return (<>
                      <BirthdayInput
                        {...field}
                      />
                      {invalid && <p className="text-red-500 text-xs mt-1">{error?.message}</p>}
                    </>)
                  }}
                />
              </div>
              <button
                className="w-full h-8 text-sm bg-sky-2 mt-3" 
                onClick={handleSubmit(onSubmit)}
              >送信する</button>
            </div>
            <div className="footer">
              <ContactFooter />
            </div>
          </div>
        </div>
        <p className="privacy">利用規約、個人情報保護方針の取扱いについて</p>
      </div>
    </Wrapper>  
  </>)
}
export default PasswordForgotModal

const BirthdayInput = forwardRef(({
  name,
  value,
  onChange,
}: any, ref) => {
  const [year, setYear] = useState<any>(1974)
  const [month, setMonth] = useState<any>('')
  const [day, setDay] = useState<any>('')

  useEffect(() => {
    if (dayjs(value, 'YYYYMMDD', true).isValid()) {
      setYear(dayjs(value).year())
      setMonth(leadingZero(dayjs(value).month() + 1))
      setDay(leadingZero(dayjs(value).date()))
    }
  }, [value])

  const onChangeYear = (value: string) => {
    setYear(value)
    const currentDate = `${value}${month}${day}`
    if (month && day && value && dayjs(currentDate, 'YYYYMMDD', true).isValid()) {
      onChange(currentDate)
    } else {
      onChange('')
    }
  }

  const onChangeMonth = (value: string) => {
    setMonth(value)
    const currentDate = `${year}${value}${day}`
    if (year && day && value && dayjs(currentDate, 'YYYYMMDD', true).isValid()) {
      onChange(currentDate)
    } else {
      onChange('')
    }
  }

  const onChangeDay = (value: string) => {
    setDay(value)
    const currentDate = `${year}${month}${value}`
    if (year && month && value && dayjs(currentDate, 'YYYYMMDD', true).isValid()) {
      onChange(currentDate)
    } else {
      onChange('')
    }
  }

  return (
    <div className="flex items-end">
      <select
        name={`${name}-year`}
        value={year}
        onChange={(e) => onChangeYear(e.target.value)}
        className="min-w-20"
      >
        <option value="">年</option>
        {/* 60年前から今年までの一覧を表示 */}
        {/* {Array.from(Array(60).keys()).map((i) => {
          return ( 
            <option key={i} value={dayjs().year() - i}>{dayjs().year() - i}</option>
          )
        })} */}
        {/* 1900年から今年までの一覧を表示 */}
        {Array.from(Array(dayjs().year() - 1900 + 1).keys()).map((i) => {
          return ( 
            <option key={i} value={1900 + i}>{1900 + i}</option>
          )
        })}
        {/* 1974年から今年までの一覧を表示 */}
        {/* {Array.from(Array(dayjs().year() - 1974 + 1).keys()).map((i) => {
          return ( 
            <option key={i} value={1974 + i}>{1974 + i}</option>
          )
        })} */}
      </select>
      <span className="text-black text-xs ms-1.5 me-2">年</span>
      <select
        name={`${name}-month`}
        value={month}
        onChange={(e) => onChangeMonth( e.target.value)}
      >
        <option value="">月</option>
        {Array.from(Array(12).keys()).map((i) => {
          return ( 
            <option key={i} value={('0' + (i+1)).slice(-2)}>{i+1}</option>
          )
        })}
      </select>
      <span className="text-black text-xs ms-1.5 me-2">月</span>
      <select
        name={`${name}-day`}
        value={day}
        onChange={(e) => onChangeDay(e.target.value)}
      >
        <option value="">日</option>
        {Array.from(Array(31).keys()).map((i) => {
          return ( 
            <option key={i} value={('0' + (i+1)).slice(-2)}>{i+1}</option>
          )
        })}
      </select>
      <span className="text-black text-xs ms-1.5">日</span>
    </div>
  )
})

const Wrapper = styled.div`
  position: absolute;
  background: url(/bg.png) no-repeat center;
  background-size: cover;
  .menu-wrapper {
    flex: 1;
  }
  .privacy {
    font-size: 14px;
    text-align: center;
    color: #fefefe;
  }
  .footer {
    padding: 15px 15px 0 15px;
  }
  input[type=email],
  select {
    width: 100%;
    padding: 5px 10px;
    font-size: 14px;
    color: #000;
    border: 1px solid #666;
    border-radius: 8px;
  }
`

