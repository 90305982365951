import { setMasterData } from "../store/appSlice"
import client from "./client"

const fetch = ()  => async (dispatch: any) => {
  const { data } = await client.get("/pet_options")
  const { status, petHealthTroubles, ...rest } = data
  if (status === 'ok') {
    const troubleOptions = (petHealthTroubles || []).map((item: any) => ({
      value: item.id,
      label: item.name,
    }))
    dispatch(setMasterData({
      ...rest,
      petHealthTroubles: troubleOptions,
    }))
  }
}

export default {
  fetch,
}
