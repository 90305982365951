import styled from "styled-components"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { Input, InputTel, InputZipcode, RegisterStep, Select } from "../../components/common"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { object, string } from "yup"
import { getAppStates, setRegisterCurrentStep, setRegisterData } from "../../store/appSlice"
import { PREFECTURES } from "../../helpers/enums"

const schema = object().shape({
  postCode: string().required('郵便番号を入力してください')
    .matches(/^\d{7}$/, '郵便番号の形式が正しくありません'),
  postCodeLeft: string().required('郵便番号を入力してください')
    .matches(/^\d{3}$/, '郵便番号の形式が正しくありません'),
  postCodeRight: string().required('郵便番号を入力してください')
    .matches(/^\d{4}$/, '郵便番号の形式が正しくありません'),
  prefecture: string().required('都道府県を選択してください'),
  municipalities: string().required('市区町村を入力してください'),
  houseNumber: string().required('建物名を入力してください'),
  phoneNumber: string().required('電話番号を入力してください')
    .matches(/^\d{10,11}$/, '電話番号の形式が正しくありません'),
});

const RegisterStep2 = () => {
  const dispatch = useAppDispatch()
  const { registerCurrentStep, registerData } = useAppSelector(getAppStates)

  const navigate = useNavigate()

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      postCode: '',
      postCodeLeft: '',
      postCodeRight: '',
      prefecture: '',
      municipalities: '',
      houseNumber: '',
      phoneNumber: '',
    }
  })

  const postCode = watch('postCode')
  useEffect(() => {
    setValue('postCodeLeft', postCode.slice(0, 3))
    setValue('postCodeRight', postCode.slice(3))
  }, [postCode])

  useEffect(() => {
    reset({
      postCode: registerData.postCode ?? '',
      prefecture: registerData.prefecture ?? '',
      municipalities: registerData.municipalities ?? '',
      houseNumber: registerData.houseNumber ?? '',
      phoneNumber: registerData.phoneNumber ?? '',
    })
  }, [])

  const onNext = (data: any) => {
    console.log(data)
    dispatch(setRegisterData(data))
    dispatch(setRegisterCurrentStep(Math.max(registerCurrentStep, 4)))
    navigate('/register/step4')
  }

  const onBack = () => {
    navigate('/register/step2')
  }

  const getAddress = () => {
    if (!postCode) return
    fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postCode}`)
      .then(res => res.json())
      .then(data => {
        const { status, results } = data
        if (status === 200) {
          const { prefcode, address2, address3 } = results[0]
          setValue('prefecture', prefcode)
          setValue('municipalities', address2)
          setValue('houseNumber', address3)
        }
      })
  }

  return (<>
    <Wrapper>
      <h1 className="title">飼い主登録<span>owner</span></h1>
      <RegisterStep index={3} />
      <h2 className="text-2xl	font-bold mt-12 mb-7">Sign UP</h2>
      <form onSubmit={handleSubmit(onNext)}>
        <div className="flex gap-4">
          <InputZipcode
            label="郵便番号"
            name={'postCode'}
            control={control}
          />
          <div className="mt-[28px]">
            <button
              type="button"
              className="font-bold h-10 px-1 sky-1"
              onClick={getAddress}
            >住所検索</button>
          </div>
        </div>
        <Select
          label="都道府県"
          name={'prefecture'}
          control={control}
          options={PREFECTURES}
          controlClassName="!w-28"
        />
        <Input
          label="市区町村"
          name={'municipalities'}
          control={control}
        />
        <Input
          label="番地以降"
          name={'houseNumber'}
          control={control}
        />
        <InputTel
          label="電話番号"
          name={'phoneNumber'}
          control={control}
        />
        <div className="mt-10">
          <button
            type="submit"
            className="btn bg-green"
          >次へ</button>
          <button
            type="button"
            className="btn sky-1 mt-4"
            onClick={onBack}
          >戻る</button>
        </div>
      </form>
    </Wrapper>
  </>)
}

export default RegisterStep2

const Wrapper = styled.div`
  h1 {
    padding: 20px 0;
    span {
      top: 28px;
    }
  }
`



