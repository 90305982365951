import { Control, Controller } from "react-hook-form"
import styled from "styled-components"
import { valueToLabel } from "../../helpers/functions"

interface Props {
  label?: string,
  name?: string,
  control?: Control<any>,
  options?: any[],
  readOnly?: boolean,
  controlClassName?: string
}

const FormSelect = ({
  label,
  name = 'select',
  control,
  options = [],
  readOnly = false,
  controlClassName = '',
}: Props) => {
  return (<>
    <Wrapper className="mb-[14px]">
      {!!label && <label className="inline-block text-sm mb-1 font-bold">{label}</label>}
      <Controller
        name={name}
        control={control}
        render={({field: {name, value, onChange}, fieldState}) => {
          const { error, invalid } = fieldState
          return <>
            <div>
              {!readOnly ? <>
                <select
                  name={name}
                  value={value}
                  onChange={onChange}
                  className={`h-10 px-2 bg-white text-black ${controlClassName}`}
                >
                  <option value={''}>選択してください</option>
                  {options.map((el: any, i: number) => 
                    <option key={i} value={el.value}>{el.label}</option>
                  )}
                </select>
              </> : <>
                <input
                  type="text"
                  value={valueToLabel(value, options)}
                  className={`h-10 bg-white text-black px-3.5 ${controlClassName}`}
                  readOnly
                />
              </>}
            </div>
            {invalid && <span className="text-rose-500 font-bold text-sm">{error?.message}</span>}
          </>
        }}
      />
    </Wrapper>
  </>)
}

export default FormSelect

const Wrapper = styled.div`
  select {
    width: 100%;
  }
  input:focus-visible {
    outline: none;
  }
`
