import dayjs from "dayjs"
import { forwardRef, useEffect, useMemo, useState } from "react"
import { Control, Controller } from "react-hook-form"
import styled from "styled-components"
import { leadingZero } from "../../helpers/functions"

interface Props {
  label?: string,
  name?: string,
  control?: Control<any>,
  readOnly?: boolean,
  minYear?: number,
  defaultYear?: number,
}

const FormDate = ({
  label,
  name = 'date',
  control,
  readOnly = false,
  minYear,
  defaultYear,
}: Props) => {
  const defaultMinYear = useMemo(() =>{
    return minYear ?? dayjs().year() - 60
  }, [minYear])

  return (<>
    <Wrapper>
      {!!label && <label>{label}</label>}
      <Controller
        control={control}
        name={name}
        render={({field, fieldState}) => {
          const { error, invalid } = fieldState

          return <>
            <DateInput
              {...field}
              readOnly={readOnly}
              minYear={defaultMinYear}
              defaultYear={defaultYear}
            />
            {invalid && <span className="text-rose-500 font-bold text-sm">{error?.message}</span>}
          </>
        }}
      />
    </Wrapper>
  </>)
}

export default FormDate

const DateInput = forwardRef(({
  name,
  value,
  onChange,
  readOnly,
  minYear,
  defaultYear,
}: any, ref) => {
  const [year, setYear] = useState<string | number>(defaultYear ?? '')
  const [month, setMonth] = useState<string | number>('')
  const [day, setDay] = useState<string | number>('')

  useEffect(() => {
    if (dayjs(value, 'YYYYMMDD', true).isValid()) {
      setYear(dayjs(value).year())
      setMonth(leadingZero(dayjs(value).month() + 1))
      setDay(leadingZero(dayjs(value).date()))
    }
  }, [value])

  const onChangeYear = (value: string) => {
    setYear(value)
    const currentDate = `${value}${month}${day}`
    if (month && day && value && dayjs(currentDate, 'YYYYMMDD', true).isValid()) {
      onChange(currentDate)
    } else {
      onChange('')
    }
  }

  const onChangeMonth = (value: string) => {
    setMonth(value)
    const currentDate = `${year}${value}${day}`
    if (year && day && value && dayjs(currentDate, 'YYYYMMDD', true).isValid()) {
      onChange(currentDate)
    } else {
      onChange('')
    }
  }

  const onChangeDay = (value: string) => {
    setDay(value)
    const currentDate = `${year}${month}${value}`
    if (year && month && value && dayjs(currentDate, 'YYYYMMDD', true).isValid()) {
      onChange(currentDate)
    } else {
      onChange('')
    }
  }

  return (
    <div className="group">
      {!readOnly ? <>
        <select
          name={`${name}-year`}
          value={year}
          onChange={(e) => onChangeYear(e.target.value)}
        >
          <option value={''}></option>
          {/* 60年前から今年までの一覧を表示 */}
          {/* {Array.from(Array(60).keys()).map((i) => {
            return ( 
              <option key={i} value={dayjs().year() - i}>{dayjs().year() - i}</option>
            )
          })} */}
          {/* 1900年から今年までの一覧を表示 */}
          {Array.from(Array(dayjs().year() - minYear + 1).keys()).map((i) => {
            return ( 
              <option key={i} value={dayjs().year() - i}>{dayjs().year() - i}</option>
            )
          })}
        </select>
      </> : <>
        <input
          type="text"
          value={year}
          readOnly
          className="w-[90px] h-[50px] text-black px-3.5"
        />
      </>}
      <span>年</span>
      {!readOnly ? <>
        <select
          name={`${name}-month`}
          value={month}
          onChange={(e) => onChangeMonth( e.target.value)}
        >
          <option value={''}></option>
          {Array.from(Array(12).keys()).map((i) => {
            return ( 
              <option key={i} value={('0' + (i+1)).slice(-2)}>{i+1}</option>
            )
          })}
        </select>
      </> : <>
        <input
          type="text"
          value={month}
          readOnly
          className="w-[90px] h-[50px] text-black px-3.5"
        />
      </>}
      <span>月</span>
      {!readOnly ? <>
        <select
          name={`${name}-day`}
          value={day}
          onChange={(e) => onChangeDay(e.target.value)}
        >
          <option value={''}></option>
          {Array.from(Array(31).keys()).map((i) => {
            return ( 
              <option key={i} value={('0' + (i+1)).slice(-2)}>{i+1}</option>
            )
          })}
        </select>
      </> : <>
        <input
          type="text"
          value={day}
          readOnly
          className="w-[90px] h-[50px] text-black px-3.5"
        />
      </>}
      <span>日</span>
    </div>
  )
})

const Wrapper = styled.div`
  margin-bottom: 14px;
  > label {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .group {
    display: flex;
    align-items: flex-end;
    select {
      width: 90px;
      height: 50px;
      padding: 0 10px;
      color: #000;
      background-color: #fff;
      border: none;
      outline: none;
    }
    span {
      display: inline-block;
      margin: 0 5px;
    }
  }
`
