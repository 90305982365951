import styled from "styled-components"
import { Input, InputPassword } from "../../components/common"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { object, ref, string } from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AuthService } from "../../api"
import { useApp } from "../../hooks"
import { useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"

const schema = object().shape({
  newPassword: string().defined().min(8, '8文字以上で入力してください')
    .required('新しいパスワードを入力してください'),
  confirmPassword: string().defined().required('新しいパスワードを再度入力してください')
    .oneOf([ref('newPassword')], '新しいパスワードが一致しません')
})

const defaultValues = {
  newPassword: '',
  confirmPassword: '',
}

const ResetPassword = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { setIsLoading } = useApp()

  const [msg, setMsg] = useState('')
  const [isValidToken, setisValidToken] = useState(false)

  const params = useMemo(() => {
    return Object.fromEntries(searchParams)
  }, [searchParams])

  useEffect(() => {
    const verify = async (params: any = {}) => {
      try {
        setIsLoading(true)
        const {data} = await AuthService.verifyResetPassToken(params)
        const { status } = data
        if (status == 'ok') {
          setisValidToken(true)
        }
      } catch (err: any) {
        const { data } = err
        setMsg(data.messageJp)
      } finally {
        setIsLoading(false)
      }
    }

    if (!params['k'] || !params['r']) {
    } else {
      verify({
        k: params['k'],
        r: params['r']
      })
    }
  }, [])

  const {
    reset,
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true)
      const { data } = await AuthService.resetPass({
        k: params['k'],
        r: params['r'],
        password: values.newPassword
      })
      const { status } = data
      if (status == 'ok') {
        reset(defaultValues)
        navigate('/login')
        toast.success('パスワードの再設定が完了しました。')
      }
    } catch (err: any) {
      const { status, data } = err
      if (status === 400) {
        toast.error(data?.messageJp ?? 'パスワードの再設定に失敗しました。')
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (<>
    <Wrapper>
      <h1 className="title">パスワードの再設定<span>PASSWORD</span></h1>
      <div>
        {isValidToken ? <>
          <div className="label-new">
            <label>新しいパスワード</label>
            <p>半角英数字を混在させ、8文字以上で設定してください。</p>
            <p>記号は使えません。（例：#、&、:、;、など）</p>
          </div>
          <InputPassword
            name="newPassword"
            control={control}
          />
          <InputPassword
            label="確認のためもう一度ご入力ください。"
            name="confirmPassword"
            control={control}
          />
          <button
            className="btn bg-green"
            onClick={handleSubmit(onSubmit)}
          >再設定する</button>
        </> : <>
          <p className="text-center">{msg}</p>
        </>}
        <div className="center link-wrapper">
          <Link to={'/login'} className="link">ログインページに戻る</Link>
        </div>
      </div>
    </Wrapper>
  </>)
}

export default ResetPassword

const Wrapper = styled.div`
  h1 {
    padding: 60px 0 50px;
  }
  button {
    margin-top: 40px;
  }
  .label-new {
    font-size: 13px;
    line-height: 1.3;
    margin: 20px 0 8px;
    label {
      display: inline-block;
      font-size: 14px;
      color: #fff;
      font-weight: bold;
      margin-bottom: 2px;
    }
  }
  .link-wrapper {
    margin-top: 20px;
    a {
      font-weight: bold;
      color: #00e6fb;
    }
  }
`
