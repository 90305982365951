import styled from "styled-components"
import { QuestionHeader } from "./components"
import { Link, useNavigate } from "react-router-dom"
import { RadioBoxGroup } from "../../components/common"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { object, string } from "yup"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { getAppStates, setNewPetData } from "../../store/appSlice"
import { YN } from "../../helpers/enums"
import { useEffect } from "react"

const schema = object().shape({
  isHospitalWorking: string().required('通院中かどうかを選択してください'),
})

const Question9 = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { newPetData } = useAppSelector(getAppStates)

  const {
    reset,
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      isHospitalWorking: '',
    }
  })

  useEffect(() => {
    if (newPetData.petBreedList) {
      reset({
        isHospitalWorking: newPetData.isHospitalWorking
      })
    }
  }, [])

  const onNext = (values: any) => {
    dispatch(setNewPetData(values))
    navigate('/pets/new/ques10')
  }


  return (<>
    <Wrapper className="flex flex-col flex-1">
      <div className="flex-1 mb-5">
        <QuestionHeader
          index="9"
        >
          <div className="font-bold">
            <p className="leading-5">{newPetData.name ?? 'ペット'}は<br />現在通院中ですか？</p>
          </div>
        </QuestionHeader>
        <div>
          <RadioBoxGroup
            name="isHospitalWorking"
            control={control}
            options={YN}
          />
        </div>
      </div>
      <button
        className="btn bg-green"
        onClick={handleSubmit(onNext)}
      >次へ</button>
      <button className="btn sky-1 mt-4"
        onClick={() => navigate('/pets/new/ques8')}
      >戻る</button>
    </Wrapper>
  </>)
}

export default Question9

const Wrapper = styled.div`
  
`

