import styled from "styled-components"
import { QuestionHeader } from "./components"
import { Link, useNavigate } from "react-router-dom"
import { CheckGroup, Textarea } from "../../components/common"
import { useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { array, object, string } from "yup"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { getAppStates, setNewPetData } from "../../store/appSlice"
import { useEffect, useMemo } from "react"

const schema = object().shape({
  petHealthTroubleList: array()
    .min(1, '健康状態の悩みを選択してください'),
  healthTroubleComment: string().defined().nullable(),
})

const Question8 = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { newPetData, masterData } = useAppSelector(getAppStates)

  const {
    reset,
    control,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      petHealthTroubleList: [],
      healthTroubleComment: '',
    }
  })

  useEffect(() => {
    reset({
      petHealthTroubleList: (newPetData.petHealthTroubleList ?? '').split(','),
      healthTroubleComment: newPetData.healthTroubleComment,
    })
  }, [])

  const onNext = (values: any) => {
    dispatch(setNewPetData({
      ...values,
      petHealthTroubleList: (values.petHealthTroubleList ?? []).filter((el: any) => !!el).join(',')
    }))
    navigate('/pets/new/ques9')
  }

  return (<>
    <Wrapper className="relative">
      <QuestionHeader
        index="8"
      >
        <div className="font-bold">
          <p className="leading-5">{newPetData.name ?? 'ペット'}の<br />健康上のお悩みは？</p>
        </div>
      </QuestionHeader>
      <div>
        <div className="mb-8">
          <CheckGroup
            name={'petHealthTroubleList'}
            control={control}
            options={masterData.petHealthTroubles ?? []}
          />
        </div>
        <div className="mb-10">
          <Textarea
            label="その他、お悩みがあれば記入してください"
            name={'healthTroubleComment'}
            control={control}
            rows={2}
          />
        </div>
      </div>
      <button
        className="btn bg-green"
        onClick={handleSubmit(onNext)}
      >次へ</button>
      <button className="btn sky-1 mt-4"
        onClick={() => navigate('/pets/new/ques7')}
      >戻る</button>
    </Wrapper>
  </>)
}

export default Question8

const Wrapper = styled.div`
  
`

