import styled from "styled-components"
import { useApp, useAppDispatch } from "../../hooks"
import { Input, InputPassword } from "../../components/common"
import { Link, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { object, string } from "yup"
import { AuthService, PetsService } from "../../api"
import { setIsAuthenticated, setIsEmptyPet, setSelectedPet } from "../../store/appSlice"
import { ContactModal, PasswordForgotModal } from "../../components/modals"

const defaultValues = {
  mailOrPhone: '',
  password: '',
  autoCheck: '0',
}

const schema = object().shape({
  mailOrPhone: string().required('メールアドレスは必須です。'),
  password: string().required('パスワードは必須です。'),
  autoCheck: string().required(),
})

const Login = () => {
  const { setUser } = useApp()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setSelectedPet(null))
  }, [])

  const [isOpenPassForgotModal, setIsOpenPassForgotModal] = useState(false)
  const [isOpenContactModal, setIsOpenContactModal] = useState(false)

  const {
    control,
    setError,
    handleSubmit
  } = useForm<typeof defaultValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const login = async (values: any) => {
    try {
      const { data } = await AuthService.login(values)
      if (data.status == 'ok') {
        dispatch(setIsAuthenticated(true))
        const { data: petsData } = await PetsService.list()
        if (petsData.status == 'ok') {
          dispatch(setIsEmptyPet(petsData.pets.length == 0))
          if (petsData.pets.length == 0) {
            navigate('/pets/new/ques1')
          } else {
            navigate('/pets/list')
          }
        }
      }
    } catch (err) {
      setError('mailOrPhone', {
        type: 'custom',
        message: 'IDまたはパスワードが間違っています。'
      })
    }
  }

  return (<>
    <Wrapper>
      <h1 className="title">ログイン<span>LOGIN</span></h1>
      <div>
        <Input
          label="メールアドレス"
          name="mailOrPhone"
          control={control}
        />
        <label className="label">パスワード
          <a
            className="cursor-pointer"
            onClick={() => setIsOpenPassForgotModal(true)}
          >（パスワードを忘れた方はこちら）</a>
        </label>
        <InputPassword
          control={control}
        />
        <div className="fs-0">
          <label className="auto-login">
            <Controller
              name="autoCheck"
              control={control}
              render={({ field: {name, value, onChange} }) => (
                <input
                  type="checkbox"
                  name={name}
                  value="1"
                  checked={value == '1'}
                  onChange={(e) => onChange(e.target.checked ? '1' : '0')}
                />
              )}
            />
            <span className="checkmark"></span>
            <span className="txt">次回から自動的にログイン</span>
          </label>
        </div>
        <button
          className="btn bg-blue btn-login rounded-xl mt-5 mb-3"
          onClick={handleSubmit(login)}
        >ログイン</button>
        <div className="text-center">
          <a
            className="blue text-sm font-bold cursor-pointer"
            onClick={() => setIsOpenContactModal(true)}
          >ログインができなくなった方</a>
        </div>
        {/* <div className="sns-login mt-4 mb-3 pt-4 pb-4">
          <p className="text-center font-bold mb-3">お持ちのアカウントでログイン</p>
          <div className="flex justify-center gap-6">
            <button>
              <img src="/sns1.png" alt="" className="w-14 h-14" />
            </button>
            <button>
              <img src="/sns2.png" alt="" className="w-14 h-14" />
            </button>
            <button>
              <img src="/sns3.png" alt="" className="w-14 h-14" />
            </button>
          </div>
        </div> */}
        <div className="text-center text-lg font-bold mt-6">
          <p>会員ではないですか？<Link to={'/register/step1'} className="blue">新規登録する</Link></p>
        </div>
      </div>
      <PasswordForgotModal
        isOpen={isOpenPassForgotModal}
        onClose={() => setIsOpenPassForgotModal(false)}
      />
      <ContactModal
        isOpen={isOpenContactModal}
        onClose={() => setIsOpenContactModal(false)}
      />
    </Wrapper>
  </>)
}

export default Login

const Wrapper = styled.div`
  h1 {
    padding: 30px 0;
    span {
      top: 38px;
    }
  }
  .label {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    a {
      color: #0b308f;
    }
  }
  .auto-login {
    display: inline-flex;
    align-items: end;
    margin-top: 24px;
    .checkmark {
      display: inline-block;
      width: 32px;
      height: 32px;
      background-color: #fff;
    }
    .txt {
      font-size: 16px;
      font-weight: bold;
      margin-left: 10px;
    }
    input[type=checkbox] {
      display: none;
      &:checked + .checkmark {
        background: #fff url(/icon-check.png) no-repeat center;
        background-size: 18px;
      }
    }
  }
  .sns-login {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
`



