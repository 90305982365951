import { useNavigate } from "react-router-dom"

const BtnNewApplicate = () => {
  const navigate = useNavigate()

  return (<>
    <button
      className="flex w-80 gap-2 py-3 px-4 rounded-xl m-auto bg-blue"
      onClick={() => navigate('/sinsei')}
    >
      <figure>
        <img src="/icon-page.png" alt="" className="h-11" />
      </figure>
      <div className="text-left">
        <p className="text-2xl font-bold leading-none mb-1.5">新規検査申込</p>
        <p className="text-sm leading-none">被毛送付前の事前登録が必要です</p>
      </div>
    </button>
  </>)
}

export default BtnNewApplicate
