import { CSSProperties } from "react"
import { Control, Controller } from "react-hook-form"
import styled from "styled-components"

interface Props {
  label?: string,
  name?: string,
  control?: Control<any>,
  type?: string,
  readOnly?: boolean,
  controlClassName?: string,
  className?: string,
  placeholder?: string,
}

const Input = ({
  label,
  name = 'name',
  control,
  type = 'text',
  placeholder = '',
  readOnly = false,
  controlClassName = '',
  className = '',
}: Props) => {
  return (<>
    <Wrapper className={className}>
      {!!label && <label>{label}</label>}
      <Controller
        name={name}
        control={control}
        render={({field, fieldState}) => {
          const { error, invalid } = fieldState
          return <>
            <input
              type={type}
              {...field}
              className={controlClassName}
              readOnly={readOnly}
              placeholder={placeholder}
            />
            {invalid && <span className="text-rose-500 font-bold text-sm">{error?.message}</span>}
          </>
        }}
      />
    </Wrapper>
  </>)
}

export default Input

const Wrapper = styled.div`
  margin-bottom: 14px;
  label {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 5px;
  }
  input {
    width: 100%;
    height: 40px;
    color: #000;
    padding: 10px 15px;
    background-color: #fff;
    border: none;
    outline: none;
  }
`
