import { Link, createSearchParams, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { Footer, LinkGroup, SearchSelect } from "../../components/common"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { array, object, string } from "yup"
import { useEffect, useState } from "react"
import { useApp } from "../../hooks"
import { PetfoodService } from "../../api"

const schema = object().shape({
  m1: array().defined().nullable(),
  m2: array().defined().nullable(),
  m3: array().defined().nullable(),
})

const FoodSupport3 = () => {
  const {id: petId} = useParams()
  const navigate = useNavigate()
  const {setIsLoading} = useApp()

  const [masters, setMasters] = useState<any>(null)

  const {
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      m1: [],
      m2: [],
      m3: [],
    }
  })

  useEffect(() => {
    const getMaster = async () => {
      try {
        setIsLoading(true)
        const {data} = await PetfoodService.fetchMaster()
        const {status, list} = data
        if (status == 'ok') {
          const tmp = (list ?? [])
            .reduce((acc: any, el: any) => {
              if (!el.group) return acc
              if (Object.keys(acc).includes(el.group)) {
                acc[el.group].push({
                  value: el.materialId,
                  label: el.name
                })
              } else {
                acc[el.group] = [{
                  value: el.materialId,
                  label: el.name
                }]
              }
              return acc
            }, {})
          setMasters(tmp)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    getMaster()
  }, [])

  const onSearch = (values: any) => {
    const mIds = Object.values(values).filter((el: any) => !!el && el.length).join(',')
    navigate({
      pathname: `/pets/${petId}/foods/search`,
      search: `${createSearchParams({mIds})}`
    })
  }

  return (<>
    <Wrapper>
      <h1>排除したい成分から探す</h1>
      <div className="form-group">
        <label className="form-label">肉類／魚介類／卵類</label>
        <SearchSelect
          name="m1"
          control={control}
          options={masters?.['肉類/魚介類/卵類'] ?? []}
          isMulti
        />
      </div>
      <div className="form-group">
        <label className="form-label">穀物／豆類／イモ類</label>
        <SearchSelect
          name="m2"
          control={control}
          options={masters?.['穀物/豆類/イモ類'] ?? []}
          isMulti
        />
      </div>
      <div className="form-group">
        <label className="form-label">油脂類</label>
        <SearchSelect
          name="m3"
          control={control}
          options={masters?.['油脂類'] ?? []}
          isMulti
        />
      </div>
      <button
        className="btn-box bg-blue"
        onClick={handleSubmit(onSearch)}
      >結果をみる</button>
      <Footer />
      <LinkGroup petId={petId} className="mt-16" />
    </Wrapper>
  </>)
}

export default FoodSupport3

const Wrapper = styled.div`
  h1 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding: 24px 0;
    margin: 20px 0 40px;
    background: url(/txt01.png) no-repeat center;
    background-size: auto 70px;
  }
  .form-group {
    margin-bottom: 14px;
  }
  .btn-box {
    font-size: 22px;
  }
`
