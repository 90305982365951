import { Link } from "react-router-dom"
import styled from "styled-components"

interface Props {
  type?: 'link' | 'button',
  label?: string,
  onClick?: () => void,
  to?: string,
  className?: string,
}

const BackLink = ({
  type = 'link',
  label = 'マイページに戻る',
  onClick,
  to,
  className,
}: Props) => {
  return (<>
    <Wrapper className={className}>
      {type == 'link' ? <>
        <Link to={'#'} className="w-full">{label}</Link>
      </> : <>
        <button className="w-full h-10 font-bold"
          onClick={() => {
            if (onClick) {
              onClick()
            }
          }}
        >{label}</button>
      </>}
    </Wrapper>
  </>)
}

export default BackLink

const Wrapper = styled.div`
  text-align: center;
  a {
    display: inline-block;
    font-weight: bold;
    color: #00e6fb;
    margin: 20px 0 0;
  }
  button {
    color: #00e6fb;
  }
`
