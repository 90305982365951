import styled from "styled-components"
import { QuestionHeader } from "./components"
import { Link, useNavigate } from "react-router-dom"
import { Input } from "../../components/common"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { object, string } from "yup"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { getAppStates, setNewPetData } from "../../store/appSlice"
import { useEffect, useMemo } from "react"
import { valueToLabel } from "../../helpers/functions"
import { PET_TYPES } from "../../helpers/enums"

const schema = object().shape({
  name: string().required('名前を入力してください')
    .max(10, '10文字以内で入力してください')
})

const Question3 = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { newPetData } = useAppSelector(getAppStates)

  const petTypeName = useMemo(() => {
    return valueToLabel(newPetData.petTypeId, PET_TYPES)
  }, [newPetData.petTypeId])

  const {
    reset,
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    }
  })

  useEffect(() => {
    reset({
      name: newPetData.name
    })
  }, [])

  const onNext = (data: any) => {
    dispatch(setNewPetData(data))
    navigate('/pets/new/ques4')
  }

  return (<>
    <Wrapper className="flex flex-col flex-1">
      <div className="flex-1">
        <QuestionHeader
          index="3"
        >
          <div className="font-bold">
            <p className="leading-5">愛{petTypeName}のお名前は？</p>
          </div>
        </QuestionHeader>
        <div className="mt-14">
          <Input
            name={'name'}
            control={control}
            controlClassName={'!h-14 text-center'}
            placeholder={`愛${petTypeName}のお名前を入力（10字以内）`}
          />
        </div>
      </div>
      <button
        className="btn bg-green"
        onClick={handleSubmit(onNext)}
      >次へ</button>
      <button className="btn sky-1 mt-4"
        onClick={() => navigate('/pets/new/ques2')}
      >戻る</button>
    </Wrapper>
  </>)
}

export default Question3

const Wrapper = styled.div`
  
`

