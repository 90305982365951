import styled from 'styled-components';
import { Input } from '../../components/common';
import { useApp } from '../../hooks';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';

const schema = object().shape({

})

const Add = () => {
  const { setUser } = useApp()

  const {
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
    }
  })

  return (<>
    <Wrapper>
      <div className='ttl relative max-w-60 pb-5 mb-8 border-b mx-auto'>
        <h1 className="relative flex justify-center align-center text-center h-16 font-bold z-0">
          <span className="text-lg leading-5">ペットフード<br />追加申請</span>
          <span className="inline-block absolute top-4 left-0 w-full futurabt text-3xl leading-8 text-center sky-2 tracking-widest uppercase z-[-1]">Pet food</span>
        </h1>
        <img src='/img03.png' alt='' className='absolute bottom-0 right-0 w-12' />
      </div>
      <div className='text-center mb-6'>
        <label className='inline-block py-1 px-3 bg-sky-2'>商品の写真</label>
      </div>
      <div className="text-center mb-10">
        <div className="fs-0">
          <label className="relative inline-flex align-center justify-center w-36 h-36 rounded-full bg-white overflow-hidden plus-center">
            <input type="file" accept="image/*" className="hidden" />
            <img src="/img07.png" alt="" className="w-full h-full object-cover opacity-50" />
          </label>
        </div>
        <p className="text-sm mt-2">ファイルを選択してください。<br />この上にファイルを<br />ドラッグ＆ドロップしてください。</p>
      </div>
      <div>
        <label className='inline-block text-sm font-bold mb-[5px]'>メーカー名　<span className='inline-block ml-2 text-xs'>※必須</span></label>
        <Input
          control={control}
        />
      </div>
      <div>
        <label className='inline-block text-sm font-bold mb-[5px]'>商品名<span className='inline-block ml-2 text-xs'>※必須</span></label>
        <Input
          control={control}
        />
      </div>
      <button className='btn bg-blue rounded-md mt-4'>申請</button>
    </Wrapper>
  </>)
};

export default Add;

const Wrapper = styled.div`
  .ttl {
    border-bottom-color: #00b1a9;
  }
  .plus-center {
    &:before, &:after {
      content: '';
      position: absolute;
      border-radius: 999px;
      background-color: #000;
      z-index: 10;
    }
    &:before {
      width: 6px;
      height: 32px;
      top: calc(50% - 16px);
      left: calc(50% - 3px);
    }
    &:after {
      height: 6px;
      width: 32px;
      left: calc(50% - 16px);
      top: calc(50% - 3px);
    }
  }
`
