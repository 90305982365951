import styled from "styled-components"
import { BtnConfirmSearch, BtnFoodSupport } from "../../components/common/buttons"
import { useApp, useAppSelector } from "../../hooks"
import { getAppStates } from "../../store/appSlice"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { TestService } from "../../api"

const Complete = () => {
  const {id: testId} = useParams()
  const {setIsLoading} = useApp()
  const [test, setTest] = useState<any>(null)

  useEffect(() => {
    const getTest = async (testId: string) => {
      try {
        setIsLoading(true)
        const {data} = await TestService.get({id: testId})
        const {status, test} = data
        if (status == 'ok') {
          setTest(test)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }

    if (testId) {
      getTest(testId)
    }
  }, [testId])

  return (<>
    <Wrapper>
      <h1 className="relative text-center min-h-16 font-bold pt-2 z-0 mt-3 mb-6">
        <span className="text-lg">{test?.petName}さんの<br />新規検査御申込受付が<br />完了しました。</span>
        <span className="inline-block absolute top-0 left-0 w-full futurabt text-3xl leading-8 text-center sky-2 tracking-widest uppercase z-[-1]">FOOD<br />research</span>
      </h1>
      <div className="text-sm text-center leading-5 mb-10">
        <p>ありがとうございました！検査結果反映まで<br />いましばらくお待ちください。</p>
        <br />
        <p>御家族と愛するペットとの間の絆は、<br />この世界で最も美しいものの一つです。</p>
        <br />
        <p>それは、言葉では表せないほど深く、<br />繊細で、無条件の愛に満ちています。</p>
        <br />
        <p>このたびのアレミッケ不耐性検査は、<br />その絆をさらに深め、<br />健やかな未来へと導くための一歩です。</p>
        <br />
        <p>この結果を通じて、ペットの健康を守り、<br />より良いケアを提供するための洞察を<br />得ることができます。</p>
        <br />
        <p>御家族の愛とケアが、<br />ペットとの絆を新たな高みへと導くでしょう。</p>
      </div>
      <BtnFoodSupport
        petId={test?.petId}
      />
      <p className="text-center text-[15px] font-bold mt-3 mb-5">検査前、検査後の登録変更が可能<br />
        検査結果から登録したペットフードの<br />
        適正を判断したり、主原料から<br />
        ペットフードを探す事が可能です</p>
      <BtnConfirmSearch
      />
    </Wrapper>
  </>)
}

export default Complete

const Wrapper = styled.div`
`
