import client from "./client"

// サインイン（ログイン）
const login = (params: any)  => {
  return client.post("/login", params)
}

// サインアウト（ログアウト）
const logout = () => {
  return client.post("/logout")  
}

const register = (data: any) => {
  return client.post('/signin', data)
}

const verify = (params: any) => {
  return client.get('/register/verify', { params })
}

const currentUser = () => {
  return client.get('/account')
}

const forgotPassword = (data: any = {}) => {
  return client.post('/password_reset_auth', data)
}

const verifyResetPassToken = (params: any = {}) => {
  return client.get('/password_register_check', { params })
}

const resetPass = (data: any = {}) => {
  return client.post('/password_register', data)
}

export default {
  login,
  logout,
  verify,
  register,
  resetPass,
  currentUser,
  forgotPassword,
  verifyResetPassToken,
}
