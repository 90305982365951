import classNames from "classnames"
import { useEffect, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import styled from "styled-components"
import { useApp, useAppSelector } from "../../hooks"
import { PetfoodService } from "../../api"
import dayjs from "dayjs"
import { getAppStates } from "../../store/appSlice"
import { LinkGroup } from "../../components/common"

const FoodDetail = () => {
  const {id} = useParams()
  const {state} = useLocation()
  const {setIsLoading} = useApp()
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {selectedPet} = useAppSelector(getAppStates)
  const [item, setItem] = useState(state?.food ?? null)

  // useEffect(() => {
  //   const getList = async (id: string) => {
  //     // try {
  //     //   setIsLoading(true)
  //     //   const {data} = await PetfoodService.list({id})
  //     //   const {status, petfood} = data
  //     //   if (status == 'ok') {
  //     //     setItem(petfood)
  //     //   }
  //     // } catch (err) {
  //     //   console.log(err)
  //     // } finally {
  //     //   setIsLoading(false)
  //     // }
  //   }

  //   if (id && !item) 
  //     getList(id)
  // }, [id])


  const onToggle = async () => {
    if (!item) return
    try {
      setIsLoading(true)
      let status = null
      if (item?.registerState) {
        const {data} = await PetfoodService.remove({
          pid: id,
          fid: item.foodId
        })
        status = data.status
      } else {
        const {data} = await PetfoodService.register({
          pid: selectedPet?.id,
          mid: item.makerId,
          fid: item.foodId,
          mstr: item.makerName,
          fstr: item.productName,
        })
        status = data.status
      }
      if (status == 'ok') {
        setItem({
          ...item,
          registerState: !item.registerState,
        })
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (<>
    <Wrapper>
      <div className="header">
        <div className="figure">
          {!!item.affiliateTag ? <a href={item.affiliateTag} target="_blank">
            <img src={item?.imageSrc ?? '/noimg.png'} alt="" className="w-[180px] h-[180px] rounded-full object-cover" />
          </a> : <img src={item?.imageSrc ?? '/noimg.png'} alt="" className="w-[180px] h-[180px] rounded-full object-cover" />}
          <button
            className={classNames({
              'btn-toggle-reg': true,
              'on': item?.registerState,
            })}
            onClick={onToggle}
          >{item?.registerState ? '登録解除' : '登録'}</button>
        </div>
      </div>
      <div className="text-center text-[0px] mb-8">
        <a href={item.affiliateTag} target="_blank" className="inline-block">
          <img src="/btn-buy.png" alt="" className="h-14" />
        </a>
      </div>
      <div className="info">
        <div className="info-row">
          <div>
            <label className="label text-sm">メーカー</label>
          </div>
          <div className="value text-sm">{item?.makerName}</div>
        </div>
        <div className="info-row">
          <div>
            <label className="label text-sm">商品名</label>
          </div>
          <div className="value text-sm">{item?.productName}</div>
        </div>
      </div>
      <div className="center">
        <label className="label">原材料</label>
      </div>
      <div className="mark"><span>● 要停止</span><span>● 要注意</span><span>● 要観察</span></div>
      <div className="summary-wrapper">
        <div className="summary text-black text-sm mb-5" dangerouslySetInnerHTML={{__html: item?.rawMaterials}}></div>
        <p className="date">最終被毛採取日／{dayjs(item?.lastExtractionDate).format('YYYY年M月D日')}</p>
      </div>
      <div className="relative footer">
        <button
          className="absolute w-[47%] top-[60%] max-w-[256px]"
          onClick={() => window.history.back()}
        >
          <img src="/btn-back.png" alt="" />
        </button>
        <Link to="/onepoint-advice" className="btn-onepoint ml-auto">
          <img src="/img01.png" alt="" />
        </Link>
      </div>
      <LinkGroup petId={selectedPet?.id} className="mt-16" />
    </Wrapper>
  </>)
}

export default FoodDetail

const Item = ({
  item,
  index,
  totalCount,
  onRemove,
}: any) => {
  
  return (<>
  </>)
}

const Wrapper = styled.div`
  .header {
    position: relative;
    text-align: center;
    margin: 30px 0 16px;
    .index {
      position: absolute;
      top: -10px;
      left: 20px;
    }
    .figure {
      position: relative;
      display: inline-block;
      max-width: 50%;
      font-size: 0;
      .btn-toggle-reg {
        position: absolute;
        bottom: 3px;
        left: calc(100% - 4px);
        width: 80px;
        height: 34px;
        font-family: inherit;
        font-size: 15px;
        color: #fff;
        text-align: center;
        border-radius: 10px;
        background-color: #e60027;
        &.on {
          color: #fff;
          background-color: #bcbcbc;
        }
      }
    }
  }
  .info {
    margin-bottom: 40px;
  }
  .info-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    .value {
      flex: 1;
      color: #000;
      padding: 5px 12px;
      background-color: #fff;
    }
  }
  .label {
    display: inline-block;
    width: 90px;
    line-height: 2;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 10px;
  }
  .mark {
    display: flex;
    justify-content: center;
    gap: 12px;
    font-weight: bold;
    margin: 12px 0 8px;
    span:nth-child(1) {
      color: #e60027
    }
    span:nth-child(2) {
      color: #fff100
    }
    span:nth-child(3) {
      color: #007d32
    }
  }
  .summary-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    padding: 12px 12px 6px;
    background-color: #fff;
    .summary {
      flex: 1;
      .level_1 {
        color: green;
      }
      .level_2 {
        color: orange;
      }
      .level_3 {
        color: red;
      }
    }
    .date {
      font-size: 12px;
      font-weight: bold;
      color: #000;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: -60px -15px -35px 0;
    .lbl-swipe {
      position: relative;
      display: inline-block;
      text-align: center;
      color: #fff;
      line-height: 1;
      padding: 6px 20px;
      margin-bottom: 25px;
      background-color: #000;
      border-radius: 10px;
      &:after {
        content: '';
        position: absolute;
        top: calc(50% - 10px);
        left: calc(100% + 8px);
        width: 28px;
        height: 20px;
        background: url(/icon-double-right.png) no-repeat center;
        background-size: contain;
      }
    }
  }
`
