import styled from "styled-components"
import { useApp, useAppSelector } from "../../hooks"
import { getAppStates } from "../../store/appSlice"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { TestService } from "../../api"
import dayjs from "dayjs"

const List = () => {
  const {selectedPet} = useAppSelector(getAppStates)
  const {setIsLoading} = useApp()

  const [petTests, setPetTests] = useState<any[]>([])

  useEffect(() => {
    const getList = async (id: number ) => {
      try {
        setIsLoading(true)
        const {data} = await TestService.list({id})
        const {status, petTests} = data
        if (status == 'ok') {
          setPetTests(petTests)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    if (selectedPet?.id)
      getList(selectedPet.id)
  }, [])

  return (
    <Wrapper>
      <div className="flex-1">
        <h1 className="relative text-center min-h-16 font-bold pt-2 z-0 mt-3 mb-6">
          <span className="text-lg">{selectedPet?.name}さんの<br />検体提出履歴</span>
          <span className="inline-block absolute top-0 left-0 w-full futurabt text-3xl leading-8 text-center sky-2 tracking-widest uppercase z-[-1]">FOOD<br />research</span>
        </h1>
        <div className="border-t pt-3 mb-5">
          <h2 className="d-flex justify-center gap-3">
            <i><img src="/icon-search2.png" alt="" className="w-6 mt-1" /></i><span className="text-xl">検査結果一覧</span>
          </h2>
        </div>
        <div className="d-flex flex-col gap-3">
          {petTests.map((item, index) => (
            <Link
              key={index}
              to={item.inTesting == 0 ? `/test/${item.id}/send` : `/results/${item.id}`}
              className="block w-full px-4 py-1.5 text-black font-bold bg-[#f8b551]"
            >{dayjs(item.testDate).format('YYYY年MM月DD日')}｜検査結果表示</Link>
          ))}
        </div>
      </div>
      <Link
        to={`/pets/${selectedPet?.id}/info`}
        className="block w-full text-center leading-10 btn bg-green"
      >マイページ</Link>
    </Wrapper>
  )
}

export default List

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`
