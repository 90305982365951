import { forwardRef, useEffect, useState } from "react"
import { Control, Controller } from "react-hook-form"
import styled from "styled-components"

interface Props {
  label?: string,
  name?: string,
  control?: Control<any>,
  readOnly?: boolean,
}

const FormTel = ({
  label,
  name = 'tel',
  control,
  readOnly = false,
}: Props) => {

  return (<> 
    <Wrapper>
      {!!label && <label>{label}</label>}
      <Controller
        name={name}
        control={control}
        render={({field, fieldState}) => {
          const { error, invalid } = fieldState

          return <>
            <TelInput
              {...field}
              readOnly={readOnly}
            />
            {invalid && <span className="text-rose-500 font-bold text-sm">{error?.message}</span>}
          </>
        }}
      />
    </Wrapper>
  </>)
}

export default FormTel

const TelInput = forwardRef(({
  name,
  value,
  onChange,
  readOnly,
}: any, ref) => {
  const [code1, setCode1] = useState<number | string>('')
  const [code2, setCode2] = useState<number | string>('')
  const [code3, setCode3] = useState<number | string>('')

  useEffect(() => {
    if (value) {
      setCode1(value.slice(0, 3))
      setCode2(value.slice(3, 7))
      setCode3(value.slice(7))
    }
  }, [value])

  const onChangeCode = (name: string, value: number | string) => {
    let num = ''
    if (name == 'code1') {
      setCode1(value)
      num = `${value}${code2}${code3}`
    } else if (name == 'code2') {
      setCode2(value)
      num = `${code1}${value}${code3}`
    } else {
      setCode3(value)
      num = `${code1}${code2}${value}`
    }
    if (num.length == 10 || num.length == 11) {
      onChange(num)
    } else {
      onChange('')
    }
  }

  return (
    <div className="group">
      <input
        type="number"
        name={`${name}-01`}
        value={code1}
        readOnly={readOnly}
        onChange={(e) => onChangeCode('code1', e.target.value)}
      />
      <span>-</span>
      <input
        type="number"
        name={`${name}-02`}
        value={code2}
        readOnly={readOnly}
        onChange={(e) => onChangeCode('code2', e.target.value)}
      />
      <span>-</span>
      <input
        type="number"
        name={`${name}-03`}
        value={code3}
        readOnly={readOnly}
        onChange={(e) => onChangeCode('code3', e.target.value)}
      />
    </div>
  )
})

const Wrapper = styled.div`
  margin-bottom: 14px;
  > label {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .group {
    display: flex;
    align-items: center;
    input {
      width: 80px;
      height: 40px;
      color: #000;
      padding: 10px 15px;
      background-color: #fff;
      border: none;
      outline: none;
    }
    span {
      display: inline-block;
      width: 14px;
      height: 2px;
      font-size: 0;
      margin: 0 6px;
      background-color: #fff;
    }
  }
`
