import applyCaseMiddleware from "axios-case-converter"
import axios, { AxiosResponse, AxiosError, AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig, AxiosHeaders } from "axios"
// import { store } from "../store"
// import { setIsAuthenticated } from "lib/store/authSlice";
import Cookies from 'js-cookie'

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

apiClient.interceptors.request.use((config: any) => {
    if (config && config.headers) {
      if (Cookies.get("user_access_token")) {
        config.headers["Authorization"] = `Bearer ${Cookies.get("user_access_token")}`;
      }
    }

    return config;
  }
);

apiClient.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    if (response && response.headers) {
      if (response.headers['user_access_token']) {
        Cookies.set("user_access_token", response.headers['user_access_token'])
      }
    }
    return response
  },
  async (error: AxiosError) => {
    const { status, data } = error.response!

    // const originalRequest = error.config;
    if (status === 401) {
      console.log("401 error");
    //   store.dispatch(setIsAuthenticated(false))
    }

    return Promise.reject(error.response!)
  }
);

// applyCaseMiddleware:
// axiosで受け取ったレスポンスの値をスネークケース→キャメルケースに変換
// または送信するリクエストの値をキャメルケース→スネークケースに変換してくれるライブラリ

// ヘッダーに関してはケバブケースのままで良いので適用を無視するオプションを追加
const options = {
  ignoreHeaders: true,
}

const client = applyCaseMiddleware(apiClient, options)

export default client
