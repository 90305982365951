import styled from "styled-components"
import { DateSelector, Input, InputPassword, RadioGroup, Textarea } from "../../components/common"
import { Link } from "react-router-dom"
import { object, ref, string } from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { ProfilesService } from "../../api"
import { useApp, useAppSelector } from "../../hooks"
import { getAppStates } from "../../store/appSlice"
import toast from "react-hot-toast"

const schema = object().shape({
  currentPassword: string().defined().min(8, '8文字以上で入力してください')
    .required('現在のパスワードを入力してください'),
  newPassword: string().defined().required('新しいパスワードを入力してください'),
  confirmPassword: string().defined().required('新しいパスワードを再度入力してください')
    .oneOf([ref('newPassword')], '新しいパスワードが一致しません')
})

const defaultValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
}

const ChangePassword = () => {
  const { setIsLoading } = useApp()
  const { isEmptyPet } = useAppSelector(getAppStates)

  const {
    reset,
    control,
    setError,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true)
      const { data } = await ProfilesService.changePassword(values)
      const { status } = data
      if (status == 'ok') {
        toast.success('パスワードを再設定しました。')
        reset(defaultValues)
      }
    } catch (err: any) {
      const { status, data } = err
      if (status === 400) {
        setError('currentPassword', {
          type: 'custom',
          message: data.messageJp
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (<>
    <Wrapper>
      <h1 className="title">パスワードの変更<span>PASSWORD</span></h1>
      <div>
        <InputPassword
          label="現在のパスワード"
          name="currentPassword"
          control={control}
        />
        <div className="label-new">
          <label>新しいパスワード</label>
          <p>半角英数字を混在させ、8文字以上で設定してください。</p>
          <p>記号は使えません。（例：#、&、:、;、など）</p>
        </div>
        <InputPassword
          name="newPassword"
          control={control}
        />
        <InputPassword
          label="確認のためもう一度ご入力ください。"
          name="confirmPassword"
          control={control}
        />
        <button
          className="btn bg-green"
          onClick={handleSubmit(onSubmit)}
        >変更する</button>
        <div className="center link-wrapper">
          <Link to={isEmptyPet ? '/pets/new/ques1' : '/pets/list'} className="link">マイページに戻る</Link>
        </div>
      </div>
    </Wrapper>
  </>)
}

export default ChangePassword

const Wrapper = styled.div`
  h1 {
    padding: 60px 0 50px;
  }
  button {
    margin-top: 40px;
  }
  .label-new {
    font-size: 13px;
    line-height: 1.3;
    margin: 20px 0 8px;
    label {
      display: inline-block;
      font-size: 14px;
      color: #fff;
      font-weight: bold;
      margin-bottom: 2px;
    }
  }
  .link-wrapper {
    margin-top: 20px;
    a {
      font-weight: bold;
      color: #00e6fb;
    }
  }
`
